import { TranslationKeys } from "../../model";

const th: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "สวัสดี, คุณเดินทางบ่อยแค่ไหน?",
      scanning: "กำลังสแกน",
      options: {
        Always: "เสมอ",
        Mostly: "ส่วนใหญ่",
        Occasionally: "เป็นครั้งคราว",
        Never: "ไม่เคย",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "คุณเคยได้รับใบสั่งจราจรหรือไม่?",
      policeControl: "ควบคุมโดยตำรวจ",
      options: {
        Yes: "ใช่",
        "No tickets so far": "เลขที่",
      },
    },
    didYouKnow: {
      title: "คุณรู้เกี่ยวกับใบสั่งจับความเร็วหรือไม่?",
      pattern: "คุณรู้หรือไม่",
      description: "รัฐบาลรวบรวมรายได้ 6.2 พันล้านต่อปี (เมือง, เขต, หรือรัฐ) คิดเป็น 30% ของใบสั่งจราจรทั้งหมดที่ออกทุกปีทั่วโลก",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "คุณต้องการดูเรดาร์ในการนำทางหรือไม่?",
      options: {
        Yes: "ใช่",
        No: "ไม่",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "ลืมค่าปรับเรดาร์ด้วยแอปของเรา",
      options: {
        safeZone: "โซนปลอดภัย",
        niceTrip: "การเดินทางที่ดี",
        safeMoney: "ประหยัดเงิน",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "เชื่อถือได้ด้วยคะแนนเฉลี่ย 4.6",
      secondTitle: "รีวิวมากกว่า 2000+ รายการ!",
      content: {
        best: "ที่สุด",
        radarDetect: "การตรวจจับเรดาร์",
        app: "แอปพลิเคชัน",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "แนะนำอย่างยิ่ง!",
          description: "ฟีเจอร์การตรวจจับเรดาร์แม่นยำและเชื่อถือได้อย่างยิ่ง ช่วยป้องกันไม่ให้หลงทางและพบจุดที่น่าสนใจซึ่งซ่อนอยู่",
        },
        user2: {
          user: "Amelia R.",
          title: "คุ้มค่ากับห้าดาวแน่นอน!",
          description: "ชอบมาก! เรดาร์ทำงานได้ดีและช่วยชีวิตในหลายโอกาส ใช้งานง่ายและแม่นยำมาก",
        },
        user3: {
          user: "SarahM123",
          title: "ว้าว!",
          description: "ลองใช้แอปนำทางหลายตัวแล้ว แต่ตัวนี้เป็นตัวเดียวที่ให้ผลลัพธ์ตรวจจับเรดาร์ที่เชื่อถือได้อย่างสม่ำเสมอ อินเทอร์เฟซใช้งานง่าย",
        },
        user4: {
          user: "Emma J.",
          title: "แอปดีมาก!",
          description: "ช่วยให้หาทางที่ดีที่สุด เลี่ยงการจราจร และพบจุดที่น่าสนใจตามทาง แผนที่ออฟไลน์มีประโยชน์มาก และการนำทางด้วยเสียงชัดเจน",
        },
      },
    },
    yourAccountIsReady: {
      title: "บัญชีของคุณพร้อมแล้ว!",
      description: "บันทึกแผนที่คุณตั้งค่าโดยการตั้งค่าบัญชี",
    },
    plan: {
      title: "ตรวจจับระบบเรดาร์ทั้งหมดก่อนที่จะตรวจจับคุณ!",
      description: "รู้สึกปลอดภัยจากค่าปรับด้วยแผนที่ที่อัปเดตและการแจ้งเตือนเรดาร์",
      mostPopular: "ยอดนิยมที่สุด",
      payment: {
        billingDetails: "รายละเอียดการเรียกเก็บเงิน",
        payNowWith: "ชำระเงินตอนนี้ด้วย",
        yourPaymentisSecured: {
          title: "การชำระเงินของคุณปลอดภัย",
          description: "การชำระเงินดำเนินการโดย GM Appdev Limited ที่อยู่ที่ลงทะเบียนคือ Nafpliou, 15, Limassol, Cyprus, 3025",
        },
        success: {
          title: "การชำระเงินสำเร็จ",
          description: "การชำระเงินสำเร็จแล้ว คุณกำลังถูกนำไปยังแอปพลิเคชัน",
        },
        failed: {
          title: "การชำระเงินล้มเหลว",
          description: "เกิดข้อผิดพลาดระหว่างการชำระเงิน คลิกปุ่มเพื่อทดลองอีกครั้ง",
        },
      },
    },
    login: {
      dontHaveAnAccount: "ไม่มีบัญชี?",
      errors: {
        userNotFound: "ไม่พบบัญชีผู้ใช้ โปรดเข้าสู่ระบบ",
        signInDetailsAreIncorrect: "รายละเอียดการเข้าสู่ระบบไม่ถูกต้อง",
      },
      button: "เข้าสู่ระบบ",
    },
    register: {
      alreadyHaveAnAccount: "มีบัญชีอยู่แล้ว?",
      errors: {
        emailAlreadyInUse: "อีเมลนี้ถูกใช้งานแล้ว โปรดเข้าสู่ระบบ",
        registrationInDetailsAreIncorrect: "รายละเอียดการลงทะเบียนไม่ถูกต้อง",
      },
      button: "สร้างบัญชี",
    },
    feedback: {
      title: "ทำไมคุณถึงตัดสินใจยกเลิก?",
      options: {
        "Not Enough Time to Use the App": "ไม่มีเวลาใช้แอปมากพอ",
        "Interested in Other Apps": "สนใจแอปอื่น",
        "Doesn't Meet My Needs": "ไม่ตรงตามความต้องการของฉัน",
        "Lacks Motivation": "ขาดแรงจูงใจ",
        "Not User-Friendly": "ใช้งานยาก",
        "Too Expensive": "แพงเกินไป",
        Other: "อื่นๆ",
      },
    },
    paymentCancel: {
      title: "ยกเลิกการชำระเงิน",
      errors: {
        somethingWentWrong: "เกิดข้อผิดพลาดบางอย่าง",
        invalidCredential: "ไม่พบบัญชีผู้ใช้",
      },
      success: {
        title: "ยกเลิกสำเร็จ",
      },
      failed: {
        title: "การยกเลิกล้มเหลว",
        description: "เกิดข้อผิดพลาดระหว่างการยกเลิก",
      },
    },
    logOut: {
      title: "กำลังนำไปยังหน้าลงชื่อเข้าใช้...",
    },
    notFound: {
      title: "ไม่พบหน้านี้",
      description: "กำลังนำไปยังหน้าต้อนรับ...",
    },
    common: {
      continue: "ดำเนินการต่อ",
      getPlan: "รับแผน",
      payNow: "ชำระเงินตอนนี้",
      tryAgain: "ลองอีกครั้ง",
      goToApp: "ไปที่แอป",
      cancel: "ยกเลิก",
      privacy: "นโยบายความเป็นส่วนตัว",
      terms: "เงื่อนไขการใช้งาน",
      timeUnits: {
        year: "ปี",
        month: "เดือน",
        week: "สัปดาห์",
      },
      billing: {
        year: "1 ปี",
        month: "1 เดือน",
        week: "1 สัปดาห์",
        total: "รวม",
      },
      then: "จากนั้น",
      auth: {
        email: "อีเมล",
        password: "รหัสผ่าน",
        signIn: "ลงชื่อเข้าใช้",
        signUp: "ลงทะเบียน",
        errors: {
          emailIsRequired: "ต้องระบุอีเมล",
          enterValidEmailAddress: "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
          passwordIsRequired: "ต้องระบุรหัสผ่าน",
          passwordMinChar: "อย่างน้อย 8 ตัวอักษร",
        },
      },
      selectAnOption: "เลือกตัวเลือก",
    },
  },
};

export default th;
