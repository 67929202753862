import { memo, useState /*, useRef , useEffect */ } from "react";
import ReactPlayer from "react-player/lazy";
import { useNextStep, useIsMobile, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import videoSrc from "../../../assets/video/2.mov";
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import { splitText } from "../../../utils/helpers";
import AnimatedTabs from "./AnimatedTabs/AnimatedTabs";
import * as S from "./style";

const LeaveRadarFinesBehindWithOurApp: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [firstLine, secondLine] = splitText(t("leaveRadarFinesBehindWithOurApp.title"));

  const [allAnimationsCompleted, setAllAnimationsCompleted] = useState(false);

  const [play, setPlay] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const { height } = useWindowSize();

  // const videoRef = useRef<HTMLVideoElement>(null);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     const timeout = setTimeout(() => {
  //       videoRef.current?.play();
  //     }, 1000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, []);

  const onClick = () => {
    if (allAnimationsCompleted) handleContinue();
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Content>
          <CustomProgressBar step={4} />
          <S.Title>
            <S.HeaderText initial={{ y: -25, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0, duration: 2, ease: "easeOut" }}>
              <span>{firstLine}</span>
              <br />
              <span>{secondLine}</span>
            </S.HeaderText>
          </S.Title>
          <S.TabsContent
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 2, duration: 1, ease: "easeOut" }}
            onAnimationComplete={() => setAllAnimationsCompleted(true)}
          >
            <AnimatedTabs t={t} start={allAnimationsCompleted} />
          </S.TabsContent>

          <S.Video
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 2, ease: "easeOut" }}
            onAnimationComplete={() => setPlay(true)}
            height={height}
          >
            <ReactPlayer url={videoSrc} width={isMobile ? "100%" : height / 2} height="100%" playsinline muted playing={play} />
          </S.Video>

          {/* <S.Video
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 2, ease: "easeOut" }}
            ref={videoRef}
            width={isMobile ? "100%" : height / 2}
            autoPlay
            muted
            playsInline
            src={videoSrc}
          /> */}
        </S.Content>
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={allAnimationsCompleted === false} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default LeaveRadarFinesBehindWithOurApp;
