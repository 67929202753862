import styled from "@emotion/styled";

export const Main = styled.div`
  width: 90%;
  height: 100%;
  margin: 1.5rem;
  color: #fff;
  font-size: 0.875rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  word-wrap: break-word;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #232d49 !important;
  }

  &::-webkit-scrollbar-track {
    background: #232d49 !important;
  }
`;

export const Link = styled.a`
  color: #d2cef8;
`;

export const P = styled.p`
  margin: 0;
`;
