import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";
import { TFunction } from "i18next";
import { captureObject } from "../../../../../../services/sentry.service";
import { useGetPageInfo } from "../../../../../../hooks";
import { firebaseService } from "../../../../../../services/firebase.service";
import { fbq } from "../../../../../../services/metapixel.service";
import Loading from "../../../../../../components/Loading/Loading";
import * as S from "./style";

const paymentElementOptions: StripePaymentElementOptions = {
  paymentMethodOrder: ["card", "paypal", "apple_pay", "google_pay"],
  layout: "auto",
  wallets: { applePay: "auto", googlePay: "auto" },
  terms: { card: "always", paypal: "always", applePay: "always", googlePay: "always" },
};

type CheckoutFormProps = {
  onPaymentFailed: () => void;
  t: TFunction<"translation", undefined>;
};

export type Ref = HTMLDivElement;

const CheckoutForm = React.forwardRef<Ref, CheckoutFormProps>(({ onPaymentFailed, t }, ref) => {
  const { currentCohort } = useGetPageInfo();

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [paymentFormCompleted, setPaymentFormCompleted] = useState(false);

  const handleChange = (e: StripePaymentElementChangeEvent) => {
    e.complete ? setPaymentFormCompleted(true) : setPaymentFormCompleted(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    firebaseService.logFirebaseEvent("checkout_pay_button_clicked");
    fbq("trackCustom", "checkout_pay_button_clicked");
    fbq("track", "InitiateCheckout");

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${currentCohort}/plan`,
      },
    });

    captureObject(error);
    setIsLoading(false);
    onPaymentFailed();
  };

  return (
    <S.PayNow>
      <S.PayNowTitle>{t("plan.payment.payNowWith")}</S.PayNowTitle>
      <S.PaymentForm onSubmit={handleSubmit}>
        <PaymentElement className="myStyles" id="payment-element" options={paymentElementOptions} onChange={handleChange} />
      </S.PaymentForm>
      <div ref={ref}>
        <S.CustomButton onClick={handleSubmit} disabled={isLoading || !stripe || !elements || !paymentFormCompleted}>
          {isLoading ? <Loading size="1.25rem" /> : t("common.payNow")}
        </S.CustomButton>
      </div>
    </S.PayNow>
  );
});

export default CheckoutForm;
