import styled from "@emotion/styled";

export const Main = styled.div<{ heightvalue: number }>`
  position: relative;
  width: ${(props) => `${props.heightvalue / 2}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  touch-action: none;
  user-select: none;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: linear-gradient(94.77deg, #10567c -9.59%, #23dbf5 118.74%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: rgba(35, 45, 73, 1);

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    z-index: 1;
    border: 0.0625rem solid #ffffff1a;
    border-radius: 1rem;
  }
`;

export const C = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  position: relative;
`;

export const CustomButton = styled.button`
  width: 90%;
  border-radius: 1.5rem;
  height: 3.5rem;
  font-size: 1.125rem;
  background: #f05a22;
  text-transform: unset;
  margin: 1.5rem 0;
  position: relative;
  color: #fff;
  border: none;
  font-weight: 700;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #fff;
  cursor: pointer;
  z-index: 5;
`;

export const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 12rem;
`;

export const SubTitle = styled.div`
  color: #ffffffb2;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: lighter;
  max-width: 15rem;
`;
