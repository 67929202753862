import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { StripePrice } from "../../../../../model/Stripe";
import { useGetPageInfo, usePrices } from "../../../../../hooks";
import { calculateWeeklyPrice, getWeekCount } from "../../../../../utils/helpers";
import Loading from "../../../../../components/Loading/Loading";
import * as S from "./style";

type PricesProps = {
  onPriceSelected: (price: StripePrice) => void;
  loading: boolean;
  disabled: boolean;
  isElementsVisible: boolean;
  t: TFunction<"translation", undefined>;
};

const Prices: React.FC<PricesProps> = ({ onPriceSelected, loading, disabled, isElementsVisible, t }) => {
  const { currentCohort } = useGetPageInfo();
  const { prices, pricesFetch, loading: pricesLoading } = usePrices(currentCohort);

  const [selectedPrice, setSelectedPrice] = useState<StripePrice>();

  const actives = prices.filter((i) => i.active);
  const weeklyPrices = actives.sort((a, b) => {
    const weeklyA = a.unit_amount / (a.recurring.interval_count * getWeekCount(a.recurring.interval));
    const weeklyB = b.unit_amount / (b.recurring.interval_count * getWeekCount(b.recurring.interval));
    return weeklyA - weeklyB;
  });
  const bestPrice = weeklyPrices[0]; // cheapest weekly

  useEffect(() => {
    setSelectedPrice(bestPrice);
  }, [bestPrice]);

  return (
    <S.Packages>
      {prices.length > 0 ? (
        <>
          <S.Card>
            {prices
              .filter((plan) => plan.active)
              .reverse()
              .map((plan, i) => (
                <S.PackageCard
                  key={plan.id}
                  initial={{ x: i % 2 === 0 ? 100 : -100, opacity: 0 }}
                  animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: i % 2 === 0 ? 100 : -100, opacity: 0 }}
                  transition={isElementsVisible ? { delay: 1.5 + i * 0.2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
                  selected={selectedPrice?.id === plan.id}
                  onClick={() => {
                    selectedPrice?.id === plan.id ? setSelectedPrice(undefined) : setSelectedPrice(plan);
                  }}
                >
                  {bestPrice.id === plan.id && (
                    <S.BestOffer>
                      <S.OfferText>{t("plan.mostPopular")}</S.OfferText>
                    </S.BestOffer>
                  )}
                  <S.Period>
                    <S.Title>{t(`common.billing.${plan.recurring.interval}`)}</S.Title>
                  </S.Period>
                  <div>
                    <S.Price>
                      {/* <S.TotalPrice>
                        {getSymbolFromCurrency(plan.currency)}
                        {(((plan.unit_amount / 100) * 100) / 100).toFixed(2)}
                      </S.TotalPrice> */}
                      <S.TotalPrice>
                        <span>
                          {getSymbolFromCurrency(plan.currency)}
                          {calculateWeeklyPrice(plan.unit_amount, plan.recurring.interval)} /
                        </span>
                        <span>&nbsp;{t("common.timeUnits.week")}</span>
                      </S.TotalPrice>
                    </S.Price>
                  </div>
                </S.PackageCard>
              ))}
          </S.Card>
          <S.CustomButtonContainer>
            <S.CustomButton
              initial={{ opacity: 0 }}
              animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={isElementsVisible ? { delay: 2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
              disabled={prices.length === 0 || disabled}
              onClick={() => {
                if (!selectedPrice) {
                  return;
                }
                selectedPrice && onPriceSelected(selectedPrice);
              }}
            >
              {loading ? <Loading size="1.25rem" /> : t("common.continue")}
            </S.CustomButton>
          </S.CustomButtonContainer>
        </>
      ) : (
        <S.CustomButtonContainer>
          <S.CustomButton
            initial={{ opacity: 0 }}
            animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
            transition={isElementsVisible ? { delay: 2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            disabled={pricesLoading || prices.length > 0}
            onClick={() => pricesFetch()}
          >
            {pricesLoading ? <Loading size="1.25rem" /> : t("common.tryAgain")}
          </S.CustomButton>
        </S.CustomButtonContainer>
      )}
    </S.Packages>
  );
};

export default Prices;
