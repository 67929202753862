const enum PAGE_ID {
  HOW_OFTEN_DO_YOU_TRAVEL = "howOftenDoYouTravel",
  HAVE_YOU_EVER_RECEIVED_A_TRAFFIC_TICKET_BEFORE = "haveYouEverReceivedATrafficTicketBefore",
  DID_YOU_KNOW = "didYouKnow",
  WOULD_YOU_LIKE_TO_SEE_THE_RADARS_ON_THE_NAVIGATION = "wouldYouLikeToSeeTheRadarsOnTheNavigation",
  LEAVE_RADAR_FINES_BEHIND_WITH_OUR_APP = "leaveRadarFinesBehindWithOurApp",
  BEST_RADAR_DETECT_APP = "bestRadarDetectApp",
  REGISTER = "register",
  YOUR_ACCOUNT_IS_READY = "yourAccountIsReady",
  PLAN = "plan",
}

export default PAGE_ID;
