import NAVIGATION_OPTION from "../enum/NAVIGATION_OPTION";

const wouldYouLikeToSeeTheRadarsOnTheNavigationOptions = [
  {
    id: 1,
    label: NAVIGATION_OPTION.YES,
    img: "💥",
  },
  {
    id: 2,
    label: NAVIGATION_OPTION.NO,
    img: "✨",
  },
];

export default wouldYouLikeToSeeTheRadarsOnTheNavigationOptions;
