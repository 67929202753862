const splitText = (text: string) => {
  const middle = Math.floor(text.length / 2);
  const offset = Math.floor(text.length * 0.1);
  let before = text.lastIndexOf(" ", middle + offset);
  let after = text.indexOf(" ", middle + offset);

  if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
    before = after;
  }

  if (before === -1) {
    before = middle + offset;
  }

  return [text.substring(0, before), text.substring(before + 1)];
};

export default splitText;
