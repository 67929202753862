import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPageInfo, useWindowSize } from "../../../hooks";
import { Stripe } from "../../../model";
import { useAlertContext } from "../../../context";
import { splitText } from "../../../utils/helpers";
import { RetrievePaymentIntent } from "../../../model/Stripe";
import stripeService from "../../../services/stripe.service";
import { firebaseService } from "../../../services/firebase.service";
import { fbq } from "../../../services/metapixel.service";
import { getUserId } from "../../../services/user.service";
import { storage } from "../../../utils";
import { captureException } from "../../../services/sentry.service";
import Prices from "./Payment/Prices/Prices";
import Checkout from "./Payment/Checkout/Checkout";
import LazyLoadImage from "../../../components/LazyLoadImage/LazyLoadImage";
import background from "../../../assets/img/payment/background.webp";
import PaymentSuccess from "./Payment/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "./Payment/PaymentFailed/PaymentFailed";
import * as S from "./style";

const Plan = () => {
  const { currentCohort } = useGetPageInfo();

  const { t } = useTranslation();

  const [titleFirstLine, titleSecondLine] = splitText(t("plan.title"));
  const [subTitleFirstLine, subTitleSecondLine] = splitText(t("plan.description"));

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const checkoutRef = createRef<HTMLDivElement>();

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [plan, setPlan] = useState<Stripe.StripePrice>();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  useEffect(() => {
    const paymentIntentId = new URLSearchParams(window.location.search).get("payment_intent");

    if (!paymentIntentId) {
      setLoading(false);
      return;
    }

    // setShow(true);
    // setPaymentDisabled(true);
    const retrievePaymentIntent: RetrievePaymentIntent = {
      configId: window.config.CONFIG_ID,
      id: paymentIntentId,
    };

    stripeService.retrievePaymentIntent(retrievePaymentIntent).then((paymentIntent) => {
      setLoading(false);
      if (!paymentIntent) {
        return;
      }

      switch (paymentIntent.status) {
        case "succeeded":
          onPaymentSuccess(paymentIntent);
          break;
        case "processing":
          break;
        case "requires_payment_method":
          onPaymentFailed();
          break;
        default:
          onPaymentFailed();
          break;
      }
    });
  }, []);

  const onPriceSelected = async (price: Stripe.StripePrice) => {
    firebaseService.logFirebaseEvent("getmyplan_button_clicked");
    fbq("trackCustom", "getmyplan_button_clicked");
    fbq("track", "AddToCart");
    setLoading(true);

    getUserId()
      .then((userId) =>
        stripeService.payment({
          configId: window.config.CONFIG_ID,
          customerId: storage.get("customerId"),
          priceId: price.id,
          subscriptionId: plan && plan.id === price.id ? storage.get("subscriptionId") : null,
          userId: userId,
          cohortId: currentCohort,
          email: storage.get("email"),
          initialUrl: storage.get("initialUrl"),
        })
      )
      .then(({ user, subscription, customer }) => {
        storage.set("subscriptionId", subscription.id);
        storage.set("customerId", customer.id);
        setClientSecret(subscription.latest_invoice.payment_intent.client_secret);
        setLoading(false);
        setShow(true);
        setPlan(price);
      })
      .catch((err) => {
        showAlert(t("plan.payment.failed.title"), "error", { vertical: "top", horizontal: "right" });
        captureException(err);
        setLoading(false);
      });
  };

  const onTryAgain = () => {
    setClientSecret("");
    setPaymentDisabled(false);
    setPaymentFailed(false);
    setShow(true);
  };

  const onPaymentSuccess = (paymentIntent: any) => {
    setShow(true);
    setPaymentSuccess(true);
    setPaymentDisabled(true);
    firebaseService.logFirebaseEvent("checkout_payment_succeeded");
    fbq("trackCustom", "checkout_payment_succeeded");
    fbq("track", "Purchase", {
      content_ids: [paymentIntent.id],
      // eventref: 'fb_oea',
      currency: paymentIntent.currency,
      num_items: 1,
      value: paymentIntent.amount / 100.0,
    });
  };

  const onPaymentFailed = () => {
    firebaseService.logFirebaseEvent("checkout_payment_failed");
    fbq("trackCustom", "checkout_payment_failed");
    setPaymentFailed(true);
    setPaymentDisabled(true);
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Image>
          <LazyLoadImage img={background} onLoad={(loaded) => setImageLoaded(loaded)} alt="" />
        </S.Image>
        <S.Overlay />

        {isElementsVisible && (
          <>
            <S.Header>
              <S.Title
                initial={{ opacity: 0 }}
                animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
                transition={isElementsVisible ? { duration: 1, ease: "easeOut" } : { delay: 1000 }}
              >
                {/* <div>Spot All Radar Systems</div>
                <div>Before They Detect You!</div> */}
                <span>{titleFirstLine}</span>
                <br />
                <span>{titleSecondLine}</span>
              </S.Title>
              <S.SubTitle
                initial={{ y: -100, opacity: 0 }}
                animate={isElementsVisible ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                transition={isElementsVisible ? { delay: 1, duration: 1, ease: "easeOut" } : { delay: 1000 }}
              >
                {/* <div>Feel safe against the fines thanks to</div>
                <div>up-to-date maps and radar alerts.</div> */}
                <span>{subTitleFirstLine}</span>
                <br />
                <span>{subTitleSecondLine}</span>
              </S.SubTitle>
            </S.Header>
            <S.Content>
              <div style={{ marginBottom: "0rem", position: "relative", zIndex: 15 }}>
                <Prices onPriceSelected={onPriceSelected} loading={loading} disabled={paymentDisabled} isElementsVisible={isElementsVisible} t={t} />
              </div>
              {clientSecret && !loading && show && (
                <div ref={checkoutRef}>
                  <Checkout
                    backdropClick={() => {
                      setShow(false);
                      setClientSecret("");
                      setPaymentDisabled(false);
                    }}
                    plan={plan}
                    clientSecret={clientSecret}
                    onPaymentFailed={onPaymentFailed}
                    show={show}
                    t={t}
                  />
                </div>
              )}
            </S.Content>
          </>
        )}

        {/* <S.CustomButtonContainer>
          <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
            Get Plan
          </S.CustomButton>
        </S.CustomButtonContainer> */}
        <S.TextButtonsContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 4 }}>
          <S.TextButton target="_blank" rel="noopener noreferrer" href={`${window.location.origin}/policy`}>
            {t("common.privacy")}
          </S.TextButton>
          <S.TextButton target="_blank" rel="noopener noreferrer" href={`${window.location.origin}/tos`}>
            {t("common.terms")}
          </S.TextButton>
        </S.TextButtonsContainer>
      </S.PageContent>
      <PaymentSuccess show={paymentSuccess} t={t} />
      <PaymentFailed show={paymentFailed} onTryAgain={onTryAgain} t={t} />
    </S.Main>
  );
};

export default Plan;
