import { TranslationKeys } from "../../model";

const es: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Hola, ¿Con qué frecuencia viajas?",
      scanning: "Escaneando",
      options: {
        Always: "Siempre",
        Mostly: "Mayormente",
        Occasionally: "Ocasionalmente",
        Never: "Nunca",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "¿Alguna vez has recibido una multa de tráfico?",
      policeControl: "Control Policial",
      options: {
        Yes: "Sí",
        "No tickets so far": "No",
      },
    },
    didYouKnow: {
      title: "¿Sabías acerca de las multas por exceso de velocidad?",
      pattern: "¿Sabías Que?",
      description: "Los gobiernos recaudan 6.2 mil millones anualmente (ciudad, condado o estado). Esto es el 30% de todas las multas de tráfico emitidas anualmente.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "¿Te gustaría ver los radares en la navegación?",
      options: {
        Yes: "Sí",
        No: "No",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Deja las Multas de Radar Atrás con Nuestra Aplicación",
      options: {
        safeZone: "Zona Segura",
        niceTrip: "Buen Viaje",
        safeMoney: "Ahorrar Dinero",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Confiable con un Promedio de 4.6",
      secondTitle: "¡Más de 2000+ Reseñas!",
      content: {
        best: "La Mejor",
        radarDetect: "Detección Por Radar",
        app: "Aplicación",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "¡Muy recomendable!",
          description:
            "La función de detección de radar es increíblemente precisa y confiable. Me ha salvado de perderme y me ha ayudado a encontrar algunos lugares ocultos increíbles.",
        },
        user2: {
          user: "Amelia R.",
          title: "¡Definitivamente vale cinco estrellas!",
          description: "¡Absolutamente me encanta! El radar funciona perfectamente y ha sido un salvavidas en múltiples ocasiones. Es intuitivo, fácil de usar y muy preciso.",
        },
        user3: {
          user: "SarahM123",
          title: "¡Wow!",
          description:
            "He probado varias aplicaciones de navegación, pero esta es la única que consistentemente cumple con su promesa de detección de radar confiable. La interfaz es fácil de usar.",
        },
        user4: {
          user: "Emma J.",
          title: "¡Gran aplicación!",
          description:
            "Me ha ayudado a encontrar las mejores rutas, evitar el tráfico y descubrir lugares ocultos en el camino. Los mapas sin conexión son un salvavidas, y la navegación por voz es clara y concisa.",
        },
      },
    },
    yourAccountIsReady: {
      title: "¡Tu cuenta está lista!",
      description: "Ahora guarda tu plan personalizado configurando una cuenta",
    },
    plan: {
      title: "¡Detecta Todos los Sistemas de Radar Antes de que te Detecten!",
      description: "Siéntete seguro contra las multas gracias a los mapas actualizados y alertas de radar.",
      mostPopular: "MÁS POPULAR",
      payment: {
        billingDetails: "Detalles de Facturación",
        payNowWith: "Paga ahora con",
        yourPaymentisSecured: {
          title: "Tu pago está seguro",
          description: "El pago es procesado por GM Appdev Limited, con dirección registrada en Nafpliou, 15, Limassol, Chipre, 3025",
        },
        success: {
          title: "Pago Exitoso",
          description: "Pago realizado con éxito. Estás siendo dirigido a la aplicación.",
        },
        failed: {
          title: "Pago Fallido",
          description: "Ocurrió un error durante el pago. Haz clic en el botón para intentarlo de nuevo.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "¿No tienes una cuenta?",
      errors: {
        userNotFound: "Usuario no encontrado. Por favor Inicia Sesión",
        signInDetailsAreIncorrect: "Los detalles de inicio de sesión son incorrectos",
      },
      button: "Iniciar Sesión",
    },
    register: {
      alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
      errors: {
        emailAlreadyInUse: "El correo electrónico ya está en uso. Por favor Inicia Sesión",
        registrationInDetailsAreIncorrect: "Los detalles de registro son incorrectos",
      },
      button: "Crear Cuenta",
    },
    feedback: {
      title: "¿Por qué has decidido cancelar?",
      options: {
        "Not Enough Time to Use the App": "No tengo suficiente tiempo para usar la aplicación",
        "Interested in Other Apps": "Interesado en otras aplicaciones",
        "Doesn't Meet My Needs": "No satisface mis necesidades",
        "Lacks Motivation": "Falta de motivación",
        "Not User-Friendly": "No es fácil de usar",
        "Too Expensive": "Demasiado caro",
        Other: "Otro",
      },
    },
    paymentCancel: {
      title: "Cancelación de Pago",
      errors: {
        somethingWentWrong: "Algo salió mal.",
        invalidCredential: "Usuario no encontrado",
      },
      success: {
        title: "Cancelación Exitosa",
      },
      failed: {
        title: "Cancelación Fallida",
        description: "Ocurrió un error durante la cancelación.",
      },
    },
    logOut: {
      title: "Redirigiendo a la Página de Inicio de Sesión...",
    },
    notFound: {
      title: "Página No Encontrada.",
      description: "Redirigiendo a la Página de Bienvenida...",
    },
    common: {
      continue: "Continuar",
      getPlan: "Obtener Plan",
      payNow: "Pagar Ahora",
      tryAgain: "Intentar de Nuevo",
      goToApp: "Ir a la Aplicación",
      cancel: "Cancelar",
      privacy: "Política de Privacidad",
      terms: "Términos de Uso",
      timeUnits: {
        year: "Año",
        month: "Mes",
        week: "Semana",
      },
      billing: {
        year: "1 Año",
        month: "1 Mes",
        week: "1 Semana",
        total: "Total",
      },
      then: "Luego",
      auth: {
        email: "Correo Electrónico",
        password: "Contraseña",
        signIn: "Iniciar Sesión",
        signUp: "Registrarse",
        errors: {
          emailIsRequired: "Correo electrónico es requerido",
          enterValidEmailAddress: "Por favor ingresa una dirección de correo válida",
          passwordIsRequired: "Contraseña es requerida",
          passwordMinChar: "Mínimo 8 caracteres",
        },
      },
      selectAnOption: "Selecciona una Opción",
    },
  },
};

export default es;
