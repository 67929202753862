import { useEffect, useState } from "react";
import * as S from "./style";

const ScreenOrientationWarning = () => {
  const [orientation, setOrientation] = useState(window.orientation);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.orientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  if (orientation === 90 || orientation === -90) {
    return <S.Main />;
  }

  return null;
};

export default ScreenOrientationWarning;
