import { TranslationKeys } from "../../model";

const it: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Ciao, quanto spesso viaggi?",
      scanning: "Scansione in corso",
      options: {
        Always: "Sempre",
        Mostly: "Per lo più",
        Occasionally: "Occasionalmente",
        Never: "Mai",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Hai mai ricevuto una multa per infrazione stradale?",
      policeControl: "Controllo della polizia",
      options: {
        Yes: "Sì",
        "No tickets so far": "No",
      },
    },
    didYouKnow: {
      title: "Sai delle multe per eccesso di velocità?",
      pattern: "Lo sapevi?",
      description:
        "I governi raccolgono 6,2 miliardi all'anno (città, contea o stato). Questo rappresenta il 30% di tutte le multe per infrazioni stradali emesse annualmente nel mondo.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Vuoi vedere i radar nella navigazione?",
      options: {
        Yes: "Sì",
        No: "No",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Lascia le multe radar dietro con la nostra app",
      options: {
        safeZone: "Zona Sicura",
        niceTrip: "Buon Viaggio",
        safeMoney: "Risparmia Denaro",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Affidabile con una media di 4,6",
      secondTitle: "Oltre 2000 recensioni!",
      content: {
        best: "Il migliore",
        radarDetect: "Rilevamento Radar",
        app: "Implementazione",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Altamente raccomandato!",
          description:
            "La funzione di rilevamento radar è incredibilmente precisa e affidabile. Mi ha salvato dal perdermi e mi ha aiutato a trovare delle gemme nascoste incredibili.",
        },
        user2: {
          user: "Amelia R.",
          title: "Assolutamente vale cinque stelle!",
          description: "Lo adoro! Il radar funziona perfettamente e mi ha salvato in diverse occasioni. È intuitivo, facile da usare e molto preciso.",
        },
        user3: {
          user: "SarahM123",
          title: "Wow!",
          description:
            "Ho provato diverse app di navigazione, ma questa è l'unica che mantiene costantemente la promessa di rilevamento radar affidabile. L'interfaccia è user-friendly.",
        },
        user4: {
          user: "Emma J.",
          title: "Ottima app!",
          description:
            "Mi ha aiutato a trovare le migliori rotte, evitare il traffico e scoprire gemme nascoste lungo il percorso. Le mappe offline sono una salvezza e la navigazione vocale è chiara e concisa.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Il tuo account è pronto!",
      description: "Ora salva il tuo piano personalizzato configurando un account",
    },
    plan: {
      title: "Individua tutti i sistemi radar prima che ti rilevino!",
      description: "Sentiti sicuro contro le multe grazie a mappe aggiornate e avvisi radar.",
      mostPopular: "IL PIÙ POPOLARE",
      payment: {
        billingDetails: "Dettagli di Fatturazione",
        payNowWith: "Paga ora con",
        yourPaymentisSecured: {
          title: "Il tuo pagamento è sicuro",
          description: "Il pagamento è elaborato da GM Appdev Limited, con indirizzo registrato a Nafpliou, 15, Limassol, Cipro, 3025",
        },
        success: {
          title: "Pagamento Riuscito",
          description: "Pagamento effettuato con successo. Sei stato reindirizzato all'applicazione.",
        },
        failed: {
          title: "Pagamento Fallito",
          description: "Si è verificato un errore durante il pagamento. Clicca il pulsante per riprovare.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Non hai un account?",
      errors: {
        userNotFound: "Utente non trovato. Per favore accedi",
        signInDetailsAreIncorrect: "I dettagli di accesso sono errati",
      },
      button: "Accedi",
    },
    register: {
      alreadyHaveAnAccount: "Hai già un account?",
      errors: {
        emailAlreadyInUse: "Email già in uso. Per favore accedi",
        registrationInDetailsAreIncorrect: "I dettagli di registrazione sono errati",
      },
      button: "Crea Account",
    },
    feedback: {
      title: "Perché hai deciso di cancellare?",
      options: {
        "Not Enough Time to Use the App": "Non abbastanza tempo per usare l'app",
        "Interested in Other Apps": "Interessato ad altre app",
        "Doesn't Meet My Needs": "Non soddisfa le mie esigenze",
        "Lacks Motivation": "Manca motivazione",
        "Not User-Friendly": "Non è user-friendly",
        "Too Expensive": "Troppo costoso",
        Other: "Altro",
      },
    },
    paymentCancel: {
      title: "Annulla Pagamento",
      errors: {
        somethingWentWrong: "Qualcosa è andato storto.",
        invalidCredential: "Utente non trovato",
      },
      success: {
        title: "Annullamento Riuscito",
      },
      failed: {
        title: "Annullamento Fallito",
        description: "Si è verificato un errore durante l'annullamento.",
      },
    },
    logOut: {
      title: "Reindirizzamento alla pagina di accesso...",
    },
    notFound: {
      title: "Pagina non trovata.",
      description: "Reindirizzamento alla pagina di benvenuto...",
    },
    common: {
      continue: "Continua",
      getPlan: "Ottieni Piano",
      payNow: "Paga Ora",
      tryAgain: "Riprova",
      goToApp: "Vai all'App",
      cancel: "Annulla",
      privacy: "Politica sulla Privacy",
      terms: "Termini di Utilizzo",
      timeUnits: {
        year: "Anno",
        month: "Mese",
        week: "Settimana",
      },
      billing: {
        year: "1 Anno",
        month: "1 Mese",
        week: "1 Settimana",
        total: "Totale",
      },
      then: "Poi",
      auth: {
        email: "Email",
        password: "Password",
        signIn: "Accedi",
        signUp: "Registrati",
        errors: {
          emailIsRequired: "Email è richiesta",
          enterValidEmailAddress: "Inserisci un indirizzo email valido",
          passwordIsRequired: "Password è richiesta",
          passwordMinChar: "Minimo 8 caratteri",
        },
      },
      selectAnOption: "Seleziona un'opzione",
    },
  },
};

export default it;
