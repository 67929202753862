import styled from "@emotion/styled";

export const CustomButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  z-index: 500;
  background: transparent;
  background-blend-mode: overlay;
`;

export const CustomButton = styled.button<{ customdisabled?: number; width: number | string }>`
  width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  border-radius: 2rem;
  height: 3.5rem;
  background: #f05a22;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }

  &:hover {
    background: #f05a22 !important;
  }

  &:disabled {
    background: #f05a22 !important;
    color: #fff !important;
    opacity: 0.8;

    &:hover {
      background: #f05a22 !important;
    }
  }

  ${({ customdisabled }) =>
    customdisabled === 1 &&
    `
      opacity:0.8;
      background: #F05A22 !important;
      color: #fff !important;

      &:hover {
        background: #F05A22 !important;
      }
    `}
`;
