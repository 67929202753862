import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { useAlertContext } from "../../../context/alert";
import { howOftenDoYouTravelOptions } from "../../../utils/constants";
import img from "../../../assets/img/howOftenDoYouTravel/component.webp";
import subtract from "../../../assets/img/howOftenDoYouTravel/subtract.webp";
import frame2 from "../../../assets/img/howOftenDoYouTravel/frame2.webp";
import frame3 from "../../../assets/img/howOftenDoYouTravel/frame3.webp";
import frame4 from "../../../assets/img/howOftenDoYouTravel/frame4.webp";
import frame5 from "../../../assets/img/howOftenDoYouTravel/frame5.webp";
import LazyLoadImage from "../../../components/LazyLoadImage/LazyLoadImage";
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { splitText } from "../../../utils/helpers";
import * as S from "./style";

const HowOftenDoYouTravel: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [firstLine, secondLine] = splitText(t("howOftenDoYouTravel.title"));

  const [option, setOption] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);
  const [handFirstAnimationComplete, setHandFirstAnimationComplete] = useState(false);

  const { showAlert } = useAlertContext();

  const { height } = useWindowSize();

  const onSelected = (selectedOption: string) => {
    setOption(selectedOption);
  };

  const onClick = () => {
    if (option) {
      handleContinue(option);
    } else {
      showAlert(t("common.selectAnOption"), "warning", { vertical: "top", horizontal: "right" });
    }
  };

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Image
          initial={{ opacity: 0 }}
          animate={{
            opacity: imageLoaded ? 1 : 0,
          }}
          transition={{ opacity: { delay: 0, duration: 0.4 } }}
        >
          <LazyLoadImage img={img} onLoad={(loaded) => setImageLoaded(loaded)} alt="" />
        </S.Image>

        <S.Content>
          <S.Overlay>
            <S.Frame1
              initial={{ x: -100, opacity: 0 }}
              animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <S.Dot />
              {t("howOftenDoYouTravel.scanning")}
            </S.Frame1>
            <S.Frame2
              src={frame2}
              initial={{ x: 100, opacity: 0 }}
              animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            />
            <S.Frame3
              src={frame3}
              initial={{ x: 100, opacity: 0 }}
              animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            />
            <S.BottomFrames>
              <S.Frame4
                src={frame4}
                initial={{ x: -100, opacity: 0 }}
                animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
                transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
              />
              <S.Frame5
                src={frame5}
                initial={{ x: 100, opacity: 0 }}
                animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
                transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
              />
            </S.BottomFrames>
          </S.Overlay>

          {isElementsVisible && <CustomProgressBar step={1} />}

          <S.Title>
            <S.Hand
              initial={{ x: -100, opacity: 0 }}
              animate={!handFirstAnimationComplete ? (isElementsVisible ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }) : { x: 0, opacity: 1, rotate: [0, 45, -15, 30, -30, 0] }}
              transition={!handFirstAnimationComplete ? (isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }) : { duration: 2, ease: "easeOut" }}
              onAnimationComplete={() => {
                if (!handFirstAnimationComplete) {
                  setHandFirstAnimationComplete(true);
                }
              }}
              width={height / 2}
            >
              👋
            </S.Hand>
            <S.HeaderText
              initial={{ x: 100, opacity: 0 }}
              animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <span>{firstLine}</span>
              <br />
              <span>{secondLine}</span>
            </S.HeaderText>
          </S.Title>

          <S.OptionsContent>
            {howOftenDoYouTravelOptions.map((o, i) => (
              <S.Option
                key={o.id}
                initial={{ x: 100, opacity: 0 }}
                animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
                transition={isElementsVisible ? { delay: (i + 1) * 0.2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
                onClick={() => onSelected(o.label)}
                selected={option === o.label ? 1 : 0}
              >
                <S.OptionIcon>{o.img}</S.OptionIcon>
                <S.OptionsText>{t(`howOftenDoYouTravel.options.${o.label}`)}</S.OptionsText>
                <S.OptionSelected>{option === o.label && <S.SelectedIcon src={subtract} />}</S.OptionSelected>
              </S.Option>
            ))}
          </S.OptionsContent>
        </S.Content>
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={option === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default HowOftenDoYouTravel;
