import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context";

const PrivateRoute = ({ children }: { children: any }) => {
  const { authContext } = useAuthContext();
  const { pathname } = useLocation();

  if (authContext.isLoggedIn) {
    if (pathname === "/V1/register" || pathname === "/V1/login") {
      return <Navigate to="/V1/yourAccountIsReady" />;
    } else {
      return children;
    }
  } else {
    if (pathname === "/V1/yourAccountIsReady" || pathname === "/V1/plan") {
      return <Navigate to="/V1/register" />;
    } else {
      return children;
    }
  }
};

export default PrivateRoute;
