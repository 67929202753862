import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import img from "../../../assets/img/bestRadarDetectApp/background.webp";
import stars from "../../../assets/img/bestRadarDetectApp/stars.webp";
import leftLeaf from "../../../assets/img/bestRadarDetectApp/leftLeaf.webp";
import rightLeaf from "../../../assets/img/bestRadarDetectApp/rightLeaf.webp";
import LazyLoadImage from "../../../components/LazyLoadImage/LazyLoadImage";
import CustomReviews from "./CustomReviewsT/CustomReviews";
import CustomButton from "../../../components/CustomButton/CustomButton";
import * as S from "./style";

const BestRadarDetectApp: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);
  const [allAnimationsCompleted, setAllAnimationsCompleted] = useState(false);

  const { height } = useWindowSize();

  const onClick = () => {
    if (allAnimationsCompleted) handleContinue();
  };

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Image
          initial={{ opacity: 0 }}
          animate={{
            opacity: imageLoaded ? 1 : 0,
          }}
          transition={{ opacity: { delay: 0, duration: 0.4 } }}
        >
          <LazyLoadImage img={img} onLoad={(loaded) => setImageLoaded(loaded)} alt="" />
        </S.Image>
        <S.Content>
          <S.Overlay />

          <S.Title>
            <S.HeaderText
              initial={{ y: -25, opacity: 0 }}
              animate={isElementsVisible ? { y: 0, opacity: 1 } : { y: -25, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <div>{t("bestRadarDetectApp.firstTitle")}</div>
              <div>{t("bestRadarDetectApp.secondTitle")}</div>
            </S.HeaderText>
          </S.Title>

          <S.CircleContent
            initial={{ opacity: 0 }}
            animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
            transition={isElementsVisible ? { delay: 1, duration: 1, ease: "easeOut" } : { delay: 1000 }}
          >
            <S.Stars src={stars} />
            <S.Circle>
              <S.CircleHeader>4.6</S.CircleHeader>
              <S.CircleText
                initial={{ opacity: 0 }}
                animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
                transition={isElementsVisible ? { delay: 2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
              >
                <div>{t("bestRadarDetectApp.content.best")}</div>
                <div>{t("bestRadarDetectApp.content.radarDetect")}</div>
                <div>{t("bestRadarDetectApp.content.app")}</div>
              </S.CircleText>
              <S.LeftLeaf
                src={leftLeaf}
                initial={{ rotateY: 0 }}
                animate={isElementsVisible ? { rotateY: 180, x: "6rem" } : { rotate: 0 }}
                transition={isElementsVisible ? { delay: 3, duration: 1, ease: "easeInOut" } : { delay: 1000 }}
              />
              <S.RightLeaf
                src={rightLeaf}
                initial={{ rotateY: 0 }}
                animate={isElementsVisible ? { rotateY: -180, x: "-6rem" } : { rotate: 0 }}
                transition={isElementsVisible ? { delay: 3, duration: 1, ease: "easeInOut" } : { delay: 1000 }}
                onAnimationComplete={() => setAllAnimationsCompleted(true)}
              />
            </S.Circle>
          </S.CircleContent>
        </S.Content>
        {isElementsVisible && <CustomReviews t={t} />}
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={allAnimationsCompleted === false} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default BestRadarDetectApp;
