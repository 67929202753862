import styled from "@emotion/styled";

export const SVG = styled.svg<{ fill?: string; color?: string }>`
  fill: ${(props) => props.fill || "currentColor"};
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: ${(props) => props.color || "currentColor"};
`;
