import styled from "@emotion/styled";

export const AlertContainer = styled.div<{ origin: { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" }; severity: "info" | "warning" | "error" }>`
  position: fixed;
  background-color: ${({ severity }) => (severity === "info" ? "#2196F3" : severity === "warning" ? "#f3b541" : "#f44336")};
  color: #fff;
  padding: 1rem 1.5rem;
  bottom: ${({ origin }) => (origin.vertical === "bottom" ? "1.25rem" : "unset")};
  top: ${({ origin }) => (origin.vertical === "top" ? "1.25rem" : "unset")};
  right: ${({ origin }) => (origin.horizontal === "right" ? "1.25rem" : origin.horizontal === "left" ? "unset" : "50%")};
  left: ${({ origin }) => (origin.horizontal === "left" ? "1.25rem" : origin.horizontal === "right" ? "unset" : "50%")};
  transform: ${({ origin }) => (origin.horizontal === "center" ? "translateX(-50%)" : "unset")};
  border-radius: 1rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
`;

export const AlertIcon = styled.div`
  line-height: 0;
  margin-right: 0.625rem;
`;

export const CloseButton = styled.button`
  margin-left: 0.625rem;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  padding: 0;
  line-height: 0;
`;
