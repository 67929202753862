import { useIsMobile } from "../../hooks";
import headerBg from "../../assets/img/header/bg_header.webp";
import pinBar from "../../assets/img/header/pin_bar.webp";
import pinEvent from "../../assets/img/header/pin_event.webp";
import pinMuseum from "../../assets/img/header/pin_museum.webp";
import pinRadar from "../../assets/img/header/pin_radar.webp";
import appLogo from "../../assets/img/header/applogo.webp";
import * as S from "./style";

type PageLayoutProps = {
  children?: React.ReactNode;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <S.Header>
          <S.HeaderBg src={headerBg} alt="Header Background" />
          <S.AppLogo src={appLogo} />
          <S.AppText>
            <S.Bold>Offline</S.Bold>
            <div>Maps</div>
          </S.AppText>
          <S.PinsContainer>
            <S.PinMuseum src={pinMuseum} />
            <S.PinEvent src={pinEvent} />
            <S.PinRadar src={pinRadar} />
            <S.PinBar src={pinBar} />
          </S.PinsContainer>
        </S.Header>
      )}

      <S.Content>
        <S.Main>{children}</S.Main>
      </S.Content>
    </>
  );
};

export default PageLayout;
