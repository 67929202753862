import React, { memo } from "react";
import Svg from "../../Svg";

type Props = {
  fill?: string;
  color?: string;
  width?: string;
  height?: string;
};

const ExclamationIcon: React.FC<Props> = ({ fill = "#fff", width = "14", height = "14", color }) => (
  <Svg
    fill={fill}
    width={width}
    height={height}
    color={color}
    path="M2.828 15.984A1.328 1.328 0 1 1 1.5 14.657a1.328 1.328 0 0 1 1.328 1.327zM1.5 13.244a1.03 1.03 0 0 1-1.03-1.03V2.668a1.03 1.03 0 0 1 2.06 0v9.548a1.03 1.03 0 0 1-1.03 1.029z"
    viewBox="-8 0 19 19"
  />
);

export default memo(ExclamationIcon);
