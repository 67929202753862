import styled from "@emotion/styled";

export const ProgressContainer = styled.div`
  width: 50%;
  height: 0.25rem;
  background-color: #ffffff33;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  margin: 0.5rem auto;
  position: relative;
`;

export const ProgressBar = styled.div`
  height: 100%;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
`;
