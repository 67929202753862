import { TranslationKeys } from "../../model";

const ar: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "مرحبًا، كم مرة تسافر؟",
      scanning: "جارٍ المسح",
      options: {
        Always: "دائمًا",
        Mostly: "غالبًا",
        Occasionally: "أحيانًا",
        Never: "أبدًا",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "هل سبق أن تلقيت مخالفة مرورية من قبل؟",
      policeControl: "تحكم الشرطة",
      options: {
        Yes: "نعم",
        "No tickets so far": "لا يوجد",
      },
    },
    didYouKnow: {
      title: "هل تعرف عن مخالفات السرعة بالرادار؟",
      pattern: "هل كنت تعلم",
      description: "تجمع الحكومات 6.2 مليار سنويًا (مدينة، مقاطعة، أو ولاية). هذا هو 30% من جميع الغرامات المرورية العالمية التي تصدر سنويًا.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "هل تود رؤية الرادارات على الخريطة؟",
      options: {
        Yes: "نعم",
        No: "لا",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "تجنب الغرامات الرادارية مع تطبيقنا",
      options: {
        safeZone: "منطقة آمنة",
        niceTrip: "رحلة سعيدة",
        safeMoney: "توفير المال",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "موثوق بمتوسط تقييم 4.6",
      secondTitle: "أكثر من 2000 تقييم!",
      content: {
        best: "أفضل",
        radarDetect: "كشف الرادار",
        app: "التنفيذ",
      },
      reviews: {
        user1: {
          user: "مايك ب.",
          title: "أنصح به بشدة!",
          description: "ميزة كشف الرادار دقيقة وموثوقة للغاية. لقد أنقذتني من الضياع وساعدتني في العثور على بعض الجواهر المخفية المذهلة.",
        },
        user2: {
          user: "أميليا ر.",
          title: "تستحق بالتأكيد خمس نجوم!",
          description: "أحبها تمامًا! الرادار يعمل بشكل لا تشوبه شائبة وكان منقذًا في مناسبات متعددة. إنه بديهي وسهل الاستخدام ودقيق للغاية.",
        },
        user3: {
          user: "سارة م.",
          title: "رائع!",
          description: "لقد جربت العديد من تطبيقات التنقل، لكن هذا هو الوحيد الذي يفي بوعده دائمًا بالكشف الموثوق عن الرادار. الواجهة سهلة الاستخدام.",
        },
        user4: {
          user: "إيما ج.",
          title: "تطبيق رائع!",
          description: "لقد ساعدني في العثور على أفضل الطرق وتجنب الزحام واكتشاف الجواهر المخفية على الطريق. الخرائط دون اتصال هي منقذة، والتوجيه الصوتي واضح ودقيق.",
        },
      },
    },
    yourAccountIsReady: {
      title: "حسابك جاهز!",
      description: "الآن قم بحفظ خطتك الشخصية بإنشاء حساب",
    },
    plan: {
      title: "اكتشف جميع أنظمة الرادار قبل أن تكتشفك!",
      description: "اشعر بالأمان من الغرامات بفضل الخرائط المحدثة وتنبيهات الرادار.",
      mostPopular: "الأكثر شعبية",
      payment: {
        billingDetails: "تفاصيل الفاتورة",
        payNowWith: "ادفع الآن باستخدام",
        yourPaymentisSecured: {
          title: "دفعك آمن",
          description: "تمت معالجة الدفع بواسطة GM Appdev Limited، بعنوان مسجل في Nafpliou، 15، ليماسول، قبرص، 3025",
        },
        success: {
          title: "الدفع ناجح",
          description: "تم الدفع بنجاح. يتم توجيهك إلى التطبيق.",
        },
        failed: {
          title: "فشل الدفع",
          description: "حدث خطأ أثناء الدفع. انقر على الزر للمحاولة مرة أخرى.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "ليس لديك حساب؟",
      errors: {
        userNotFound: "المستخدم غير موجود. يرجى تسجيل الدخول",
        signInDetailsAreIncorrect: "تفاصيل تسجيل الدخول غير صحيحة",
      },
      button: "تسجيل الدخول",
    },
    register: {
      alreadyHaveAnAccount: "هل لديك حساب بالفعل؟",
      errors: {
        emailAlreadyInUse: "البريد الإلكتروني مستخدم بالفعل. يرجى تسجيل الدخول",
        registrationInDetailsAreIncorrect: "تفاصيل التسجيل غير صحيحة",
      },
      button: "إنشاء حساب",
    },
    feedback: {
      title: "لماذا قررت الإلغاء؟",
      options: {
        "Not Enough Time to Use the App": "ليس لدي وقت كافٍ لاستخدام التطبيق",
        "Interested in Other Apps": "مهتم بتطبيقات أخرى",
        "Doesn't Meet My Needs": "لا يلبي احتياجاتي",
        "Lacks Motivation": "يفتقر إلى الحافز",
        "Not User-Friendly": "غير سهل الاستخدام",
        "Too Expensive": "باهظ الثمن",
        Other: "آخر",
      },
    },
    paymentCancel: {
      title: "إلغاء الدفع",
      errors: {
        somethingWentWrong: "حدث خطأ ما.",
        invalidCredential: "المستخدم غير موجود",
      },
      success: {
        title: "تم الإلغاء بنجاح",
      },
      failed: {
        title: "فشل الإلغاء",
        description: "حدث خطأ أثناء الإلغاء.",
      },
    },
    logOut: {
      title: "إعادة التوجيه إلى صفحة تسجيل الدخول...",
    },
    notFound: {
      title: "الصفحة غير موجودة.",
      description: "إعادة التوجيه إلى صفحة الترحيب...",
    },
    common: {
      continue: "متابعة",
      getPlan: "احصل على خطة",
      payNow: "ادفع الآن",
      tryAgain: "حاول مرة أخرى",
      goToApp: "اذهب إلى التطبيق",
      cancel: "إلغاء",
      privacy: "سياسة الخصوصية",
      terms: "شروط الاستخدام",
      timeUnits: {
        year: "سنة",
        month: "شهر",
        week: "أسبوع",
      },
      billing: {
        year: "1 سنة",
        month: "1 شهر",
        week: "1 أسبوع",
        total: "المجموع",
      },
      then: "ثم",
      auth: {
        email: "البريد الإلكتروني",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        signUp: "إنشاء حساب",
        errors: {
          emailIsRequired: "البريد الإلكتروني مطلوب",
          enterValidEmailAddress: "يرجى إدخال عنوان بريد إلكتروني صالح",
          passwordIsRequired: "كلمة المرور مطلوبة",
          passwordMinChar: "الحد الأدنى 8 أحرف",
        },
      },
      selectAnOption: "اختر خيارًا",
    },
  },
};

export default ar;
