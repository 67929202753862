import React, { memo } from "react";
import Svg from "../../Svg";

type WarningProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const WarningIcon: React.FC<WarningProps> = ({ fill = "#F02222", color, size = "20px" }) => (
  <Svg fill={fill} color={color} size={size} path="" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66675 9.99935C1.66675 5.40018 5.40008 1.66602 10.0001 1.66602C14.6084 1.66602 18.3334 5.40018 18.3334 9.99935C18.3334 14.6002 14.6084 18.3327 10.0001 18.3327C5.40008 18.3327 1.66675 14.6002 1.66675 9.99935ZM9.26675 6.84102C9.26675 6.44185 9.60008 6.10768 10.0001 6.10768C10.4001 6.10768 10.7251 6.44185 10.7251 6.84102V10.5243C10.7251 10.9252 10.4001 11.2493 10.0001 11.2493C9.60008 11.2493 9.26675 10.9252 9.26675 10.5243V6.84102ZM10.0084 13.9002C9.60008 13.9002 9.27508 13.5668 9.27508 13.1668C9.27508 12.7668 9.60008 12.4418 10.0001 12.4418C10.4084 12.4418 10.7334 12.7668 10.7334 13.1668C10.7334 13.5668 10.4084 13.9002 10.0084 13.9002Z"
      fill={fill}
    />
  </Svg>
);

export default memo(WarningIcon);
