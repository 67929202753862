import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Main = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: calc(100% - 2.5rem);
  }
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled(motion.div)`
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Frame1 = styled(motion.div)`
  position: absolute;
  width: 5rem;
  height: 1.75rem;
  background: #7d7d7c;
  border-radius: 2rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 0.675rem;
  top: -1rem;
  left: 1rem;

  @media (max-width: 768px) {
    top: 0rem;
  }
`;

export const Dot = styled.div`
  background: #a5d955;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
`;

export const Frame2 = styled(motion.img)`
  position: absolute;
  width: 4rem;
  right: 0.5rem;
  top: 1rem;
  @media (max-width: 768px) {
    width: 4rem;
    top: 3rem;
  }
`;

export const Frame3 = styled(motion.img)`
  position: absolute;
  width: 2.5rem;
  right: 1.25rem;
  top: 4rem;
  @media (max-width: 768px) {
    right: 1.25rem;
    top: 6.5rem;
  }
`;

export const BottomFrames = styled.div`
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* @media (min-width: 769px) and (max-width: 1024px) {
    bottom: -1.5rem;
  } */
`;

export const Frame4 = styled(motion.img)`
  width: 8rem;
`;

export const Frame5 = styled(motion.img)`
  width: 8rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  position: relative;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 12rem;
  position: relative;
  @media (max-width: 768px) {
    height: 15rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 20rem;
  }
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 2rem);
`;

export const Hand = styled(motion.span)<{ width: number }>`
  font-size: 1.5rem;
  margin-right: 1rem;
`;

export const HeaderText = styled(motion.div)`
  /* max-width: 12rem; */
  text-align: center;
  white-space: pre-line;
`;

export const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  margin-bottom: 1.25rem;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.25rem;
    gap: 0.75rem;
  }
  @media (max-height: 700px) and (max-width: 768px) {
    margin-bottom: 0.75rem;
    gap: 0.75rem;
  }
`;

export const OptionIcon = styled.span`
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-height: 700px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const OptionsText = styled.span`
  min-width: 8rem;
  text-align: start;
`;

export const Option = styled(motion.div)<{ selected: number }>`
  width: calc(100% - 2rem);
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 1rem;
  background: ${(props) => (props.selected === 1 ? "#F6795866" : "#3D3D3D")};
  text-align: center;
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  position: relative;
  border: ${(props) => (props.selected === 1 ? "0.125rem solid #F05A22" : "0.125rem solid #3D3D3D")};
  user-select: none;
`;

export const OptionSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const SelectedIcon = styled.img`
  width: 1.5rem;
`;

export const RadioButton = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid #475399;
  border-radius: 50%;
`;

export const Bottom = styled.div<{ heightvalue: number }>`
  width: ${(props) => `${props.heightvalue / 2}px`};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;
