import styled from "@emotion/styled";
import { motion } from "framer-motion";
import Lottie from "lottie-react";

export const Main = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: calc(100% - 2.5rem);
  }
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Title = styled.div`
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
  position: relative;
  z-index: 1;
`;

export const HeaderText = styled(motion.div)``;

export const Video = styled(motion.div)<{ height: number }>`
  width: 100%;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 768px) {
    width: ${(props) => (props.height ? `${props.height / 2}px` : "25rem")};
  }
`;

export const TabsContent = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 1rem 0;
`;

export const Tab = styled(motion.div)`
  background: #f05a224d;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #ffffff33;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 6rem;

  @media (max-width: 768px) {
    min-width: 5rem;
  }
`;

export const StyledLottie = styled(Lottie)<{ height: number }>`
  user-select: none;
  width: 100%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
