import styled from "@emotion/styled";
import turnYourDevice from "../../assets/img/screenOrientationWarning/image.webp";

export const Main = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1500;
  background: url(${turnYourDevice});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
