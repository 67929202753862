import { memo, useState, useEffect } from "react";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import animationData from "../../../assets/lottie/lottie.json";
import didYouKnow from "../../../assets/img/didYouKnow/group.webp";
import LightIcon from "../../../components/Svg/Icons/Light/LightIcon";
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ColorizedText from "../../../components/ColorizedText/ColorizedText";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { splitText } from "../../../utils/helpers";
import * as S from "./style";

const DidYouKnow: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [firstLine, secondLine] = splitText(t("didYouKnow.title"));

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);
  const [lightFirstAnimationComplete, setLightFirstAnimationComplete] = useState(false);
  const [allAnimationsCompleted, setAllAnimationsCompleted] = useState(false);

  const { height } = useWindowSize();

  const onClick = () => {
    if (allAnimationsCompleted) handleContinue();
  };

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        {isElementsVisible && <S.StyledLottie animationData={animationData} loop={false} height={height} autoPlay={false} />}
        <S.Content>
          <S.Overlay>
            <S.BottomFrames>
              <S.StyledLightIcon
                initial={lightFirstAnimationComplete ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
                animate={isElementsVisible ? (lightFirstAnimationComplete ? { scale: [1, 1.1, 1] } : { x: 0, opacity: 1 }) : { x: -100, opacity: 0 }}
                transition={
                  isElementsVisible ? (lightFirstAnimationComplete ? { delay: 0, duration: 2, ease: "easeOut" } : { delay: 0, duration: 1, ease: "easeOut" }) : { delay: 1000 }
                }
                onAnimationComplete={() => {
                  if (!lightFirstAnimationComplete) {
                    setLightFirstAnimationComplete(true);
                  }
                }}
              >
                <LightIcon fill={lightFirstAnimationComplete ? "#fff" : "#000"} onLoad={(loaded) => setImageLoaded(loaded)} />
              </S.StyledLightIcon>

              <AnimatePresence>
                <S.DidYouKnow>
                  <S.Pattern
                    src={didYouKnow}
                    initial={isElementsVisible ? (lightFirstAnimationComplete ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }) : { x: 100, opacity: 0 }}
                    animate={isElementsVisible ? (lightFirstAnimationComplete ? { scale: [1, 1.1, 1] } : { x: 0, opacity: 1 }) : { x: 100, opacity: 0 }}
                    transition={
                      isElementsVisible ? (lightFirstAnimationComplete ? { delay: 0, duration: 2, ease: "easeOut" } : { delay: 0, duration: 1, ease: "easeOut" }) : { delay: 1000 }
                    }
                  />
                  <S.PatternText
                    initial={isElementsVisible ? (lightFirstAnimationComplete ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }) : { x: 100, opacity: 0 }}
                    animate={isElementsVisible ? (lightFirstAnimationComplete ? { scale: [1, 1.1, 1] } : { x: 0, opacity: 1 }) : { x: 100, opacity: 0 }}
                    transition={
                      isElementsVisible ? (lightFirstAnimationComplete ? { delay: 0, duration: 2, ease: "easeOut" } : { delay: 0, duration: 1, ease: "easeOut" }) : { delay: 1000 }
                    }
                  >
                    {t("didYouKnow.pattern")}
                  </S.PatternText>
                </S.DidYouKnow>
              </AnimatePresence>
            </S.BottomFrames>
            {isElementsVisible && (
              <S.ProgressContent>
                <CustomProgressBar step={3} />
              </S.ProgressContent>
            )}
          </S.Overlay>

          <S.Title>
            <S.HeaderText
              initial={{ opacity: 0 }}
              animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={isElementsVisible ? { delay: 2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <span>{firstLine}</span>
              <br />
              <span>{secondLine}</span>
            </S.HeaderText>
          </S.Title>

          <S.Description
            initial={{ y: -100, opacity: 0 }}
            animate={isElementsVisible ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
            transition={isElementsVisible ? { delay: 3, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            onAnimationComplete={() => setAllAnimationsCompleted(true)}
          >
            <ColorizedText text={t("didYouKnow.description")} />
          </S.Description>
        </S.Content>
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={allAnimationsCompleted === false} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default DidYouKnow;
