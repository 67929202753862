import { TFunction } from "i18next";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import CheckIcon from "../../../../../components/Svg/Icons/Check/CheckIcon";
import * as S from "./style";

type PaymentSuccessProps = {
  show: boolean;
  t: TFunction<"translation", undefined>;
};

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ show, t }) => {
  return (
    <CustomModal show={show}>
      <S.Container>
        <S.C>
          <S.Moire>
            <CheckIcon fill="#00BB61" width="3.5rem" height="3.5rem" />
          </S.Moire>
        </S.C>
        <S.Title>{t("plan.payment.success.title")}</S.Title>
        <S.SubTitle>
          {/* <div>Payment made successfully.You are</div>
          <div>directed to the application.</div> */}
          {t("plan.payment.success.description")}
        </S.SubTitle>
        <S.CustomButton onClick={() => window.open(process.env.REACT_APP_MOBILE_APP_URL, "_blank")}>{t("common.goToApp")}</S.CustomButton>
      </S.Container>
    </CustomModal>
  );
};

export default PaymentSuccess;
