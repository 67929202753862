import { TFunction } from "i18next";

const validateEmailPassword = (values: { email: string; password: string }, t: TFunction<"translation", undefined>) => {
  const errors: { email?: string; password?: string } = {};
  if (!values.email) {
    errors.email = t("common.auth.errors.emailIsRequired");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t("common.auth.errors.enterValidEmailAddress");
  }
  if (!values.password) {
    errors.password = t("common.auth.errors.passwordIsRequired");
  } else if (values.password.length < 8) {
    errors.password = t("common.auth.errors.passwordMinChar");
  }
  return errors;
};

export default validateEmailPassword;
