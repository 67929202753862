import { TranslationKeys } from "../../model";

const de: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Hallo, wie oft reisen Sie?",
      scanning: "Scannen",
      options: {
        Always: "Immer",
        Mostly: "Meistens",
        Occasionally: "Gelegentlich",
        Never: "Nie",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Haben Sie schon einmal einen Strafzettel erhalten?",
      policeControl: "Polizeikontrolle",
      options: {
        Yes: "Ja",
        "No tickets so far": "Nein",
      },
    },
    didYouKnow: {
      title: "Kennen Sie Radarfallen-Bußgelder?",
      pattern: "Wussten Sie schon",
      description: "Regierungen sammeln jährlich 6,2 Milliarden (Stadt, Landkreis oder Staat). Das sind 30 % aller jährlich weltweit ausgestellten Verkehrsstrafen.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Möchten Sie die Radare auf der Navigation sehen?",
      options: {
        Yes: "Ja",
        No: "Nein",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Lassen Sie Radarbußgelder mit unserer App hinter sich",
      options: {
        safeZone: "Sichere Zone",
        niceTrip: "Gute Reise",
        safeMoney: "Geld sparen",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Vertrauen mit einer Durchschnittsbewertung von 4,6",
      secondTitle: "Über 2000 Bewertungen!",
      content: {
        best: "Die beste",
        radarDetect: "Radarerfassung",
        app: "Umsetzung",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Sehr empfehlenswert!",
          description:
            "Die Radardetektionsfunktion ist unglaublich genau und zuverlässig. Sie hat mich vor dem Verlaufen bewahrt und mir geholfen, erstaunliche versteckte Schätze zu finden.",
        },
        user2: {
          user: "Amelia R.",
          title: "Definitiv fünf Sterne wert!",
          description:
            "Ich liebe es absolut! Das Radar funktioniert einwandfrei und hat mir schon mehrfach das Leben gerettet. Es ist intuitiv, einfach zu bedienen und sehr genau.",
        },
        user3: {
          user: "SarahM123",
          title: "Wow!",
          description:
            "Ich habe mehrere Navigations-Apps ausprobiert, aber dies ist die einzige, die ständig ihr Versprechen einer zuverlässigen Radardetektion hält. Die Benutzeroberfläche ist benutzerfreundlich.",
        },
        user4: {
          user: "Emma J.",
          title: "Tolle App!",
          description:
            "Es hat mir geholfen, die besten Routen zu finden, den Verkehr zu vermeiden und versteckte Schätze unterwegs zu entdecken. Die Offline-Karten sind eine Lebensrettung und die Sprachnavigation ist klar und präzise.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Ihr Konto ist bereit!",
      description: "Speichern Sie jetzt Ihren personalisierten Plan, indem Sie ein Konto einrichten",
    },
    plan: {
      title: "Erkennen Sie alle Radarsysteme, bevor sie Sie erkennen!",
      description: "Fühlen Sie sich sicher vor Bußgeldern dank aktueller Karten und Radarwarnungen.",
      mostPopular: "AM BELIEBTESTEN",
      payment: {
        billingDetails: "Rechnungsdetails",
        payNowWith: "Jetzt bezahlen mit",
        yourPaymentisSecured: {
          title: "Ihre Zahlung ist gesichert",
          description: "Die Zahlung wird von GM Appdev Limited bearbeitet, mit registrierter Adresse in Nafpliou, 15, Limassol, Zypern, 3025",
        },
        success: {
          title: "Zahlung erfolgreich",
          description: "Zahlung erfolgreich abgeschlossen. Sie werden zur Anwendung weitergeleitet.",
        },
        failed: {
          title: "Zahlung fehlgeschlagen",
          description: "Ein Fehler ist während der Zahlung aufgetreten. Klicken Sie auf die Schaltfläche, um es erneut zu versuchen.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Sie haben kein Konto?",
      errors: {
        userNotFound: "Benutzer nicht gefunden. Bitte einloggen",
        signInDetailsAreIncorrect: "Die Anmeldedaten sind falsch",
      },
      button: "Anmelden",
    },
    register: {
      alreadyHaveAnAccount: "Sie haben bereits ein Konto?",
      errors: {
        emailAlreadyInUse: "E-Mail bereits in Verwendung. Bitte einloggen",
        registrationInDetailsAreIncorrect: "Die Registrierungsdaten sind falsch",
      },
      button: "Konto erstellen",
    },
    feedback: {
      title: "Warum haben Sie sich entschieden zu kündigen?",
      options: {
        "Not Enough Time to Use the App": "Nicht genug Zeit, um die App zu nutzen",
        "Interested in Other Apps": "Interessiert an anderen Apps",
        "Doesn't Meet My Needs": "Erfüllt nicht meine Bedürfnisse",
        "Lacks Motivation": "Fehlende Motivation",
        "Not User-Friendly": "Nicht benutzerfreundlich",
        "Too Expensive": "Zu teuer",
        Other: "Andere",
      },
    },
    paymentCancel: {
      title: "Zahlung stornieren",
      errors: {
        somethingWentWrong: "Etwas ist schief gelaufen.",
        invalidCredential: "Benutzer nicht gefunden",
      },
      success: {
        title: "Erfolgreich storniert",
      },
      failed: {
        title: "Stornierung fehlgeschlagen",
        description: "Ein Fehler ist während der Stornierung aufgetreten.",
      },
    },
    logOut: {
      title: "Weiterleitung zur Anmeldeseite...",
    },
    notFound: {
      title: "Seite nicht gefunden.",
      description: "Weiterleitung zur Willkommensseite...",
    },
    common: {
      continue: "Fortfahren",
      getPlan: "Plan erhalten",
      payNow: "Jetzt bezahlen",
      tryAgain: "Erneut versuchen",
      goToApp: "Zur App",
      cancel: "Abbrechen",
      privacy: "Datenschutzrichtlinie",
      terms: "Nutzungsbedingungen",
      timeUnits: {
        year: "Jahr",
        month: "Monat",
        week: "Woche",
      },
      billing: {
        year: "1 Jahr",
        month: "1 Monat",
        week: "1 Woche",
        total: "Gesamt",
      },
      then: "Dann",
      auth: {
        email: "E-Mail",
        password: "Passwort",
        signIn: "Anmelden",
        signUp: "Registrieren",
        errors: {
          emailIsRequired: "E-Mail ist erforderlich",
          enterValidEmailAddress: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
          passwordIsRequired: "Passwort ist erforderlich",
          passwordMinChar: "Mindestens 8 Zeichen",
        },
      },
      selectAnOption: "Wählen Sie eine Option",
    },
  },
};

export default de;
