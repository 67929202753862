import React from "react";
import * as S from "./style";

type Props = {
  text: string;
};

const ColorizedText: React.FC<Props> = ({ text }) => {
  const regex = /(\d+(\d+\.\d+|\d+|\d+%)?|%)/g;

  const parts = text.split(regex);

  return (
    <p>
      {parts.map((part, index) => {
        if (/\d+(\.\d+)?/.test(part)) {
          return <S.ColoredText key={index}>{part}</S.ColoredText>;
        } else if (part === "%" || part === ".") {
          return <S.ColoredText key={index}>{part}</S.ColoredText>;
        }
        return part;
      })}
    </p>
  );
};

export default ColorizedText;
