import { useTranslation } from "react-i18next";
import { TPage } from "../../../model";
import { useNextStep, useWindowSize } from "../../../hooks";
import CheckIcon from "../../../components/Svg/Icons/Check/CheckIcon";
import * as S from "./style";

const YourAccountIsReady: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const { height } = useWindowSize();

  const onClick = () => {
    handleContinue();
  };

  return (
    <S.Main heightvalue={height}>
      <S.Container>
        <S.C>
          <CheckIcon width="4rem" height="4rem" />
        </S.C>
        <S.Title>{t("yourAccountIsReady.title")}</S.Title>
        <S.SubTitle>{t("yourAccountIsReady.description")}</S.SubTitle>
        <S.CustomButton onClick={onClick}>{t("common.getPlan")}</S.CustomButton>
      </S.Container>
    </S.Main>
  );
};

export default YourAccountIsReady;
