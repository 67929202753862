import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { AlertContainer, AlertIcon /*, CloseButton */ } from "./style";
// import CloseIcon from "../../components/Svg/Icons/Close/CloseIcon";
import WarningTriangle from "../../components/Svg/Icons/WarningTriangle/WarningTriangleIcon";

interface IAlertState {
  open: boolean;
  message: string;
  severity: "info" | "warning" | "error";
  origin: { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" };
}

interface IAlertContext {
  showAlert: (message: string, severity?: "info" | "warning" | "error", origin?: { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" }) => void;
}

const initialAlertState: IAlertState = {
  open: false,
  message: "",
  severity: "info",
  origin: { vertical: "bottom", horizontal: "right" },
};

const AlertContext = createContext<IAlertContext>({
  showAlert: () => {},
});

export const useAlertContext = (): IAlertContext => useContext(AlertContext);

export const AlertContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [alertState, setAlertState] = useState<IAlertState>(initialAlertState);

  const showAlert = (message: string, severity: "info" | "warning" | "error" = "info", origin?: { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" }) => {
    setAlertState({ open: true, message, severity, origin: origin || initialAlertState.origin });
  };

  const hideAlert = () => {
    setAlertState({ ...alertState, open: false });
  };

  useEffect(() => {
    if (alertState.open) {
      const timer = setTimeout(() => {
        hideAlert();
      }, 2000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertState.open]);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alertState.open && (
        <AlertContainer origin={alertState.origin} severity={alertState.severity}>
          <AlertIcon>
            <WarningTriangle width="2rem" height="1.75rem" fill="#fff" />
          </AlertIcon>
          <span>{alertState.message}</span>
          {/* <CloseButton onClick={hideAlert}>
            <CloseIcon />
          </CloseButton> */}
        </AlertContainer>
      )}
    </AlertContext.Provider>
  );
};
