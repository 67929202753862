import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  background: #444444;
  border: 0.0625rem solid #6b6b6b;
  border-radius: 1rem;
  line-height: 1rem;
  margin: 0 0.5rem;
  height: 7.5rem;
  max-width: 15rem;
  flex-shrink: 0;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin: 0.5rem 0;
`;

export const UserName = styled.div`
  display: block;
  font-size: 0.75rem;
`;

export const ReviewTitle = styled.div`
  display: block;
  min-width: 8rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #fff;

  @media (max-height: 780px) and (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

export const ReviewText = styled.div`
  color: #fff;
  font-size: 0.675rem;

  @media (max-height: 780px) and (max-width: 768px) {
    font-size: 0.675rem;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  gap: 0.5rem;
  width: 100%;
  max-width: 25rem;
  display: flex;
  justify-content: center;
  /* margin-top: 0.5rem; */
`;

export const MotionDiv = styled(motion.div)`
  display: flex;
  gap: 1rem;
`;
