import { createBrowserHistory } from "history";
import AppRouter from "./AppRouter/AppRouter";
import { BrowserRouter } from "react-router-dom";
import { AnswersContextProvider, AlertContextProvider, AuthContextProvider } from "../context";
import ScreenOrientationWarning from "../components/ScreenOrientationWarning/ScreenOrientationWarning";
import "../i18n";

export const history = createBrowserHistory();

const App = () => {
  return (
    <AuthContextProvider>
      <AnswersContextProvider>
        <AlertContextProvider>
          <BrowserRouter>
            <ScreenOrientationWarning />
            <AppRouter />
          </BrowserRouter>
        </AlertContextProvider>
      </AnswersContextProvider>
    </AuthContextProvider>
  );
};

export default App;
