import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { captureException } from "../../../services/sentry.service";
import { useFormik } from "formik";
import { helpers, storage } from "../../../utils";
import { useAuthContext } from "../../../context";
import { useAuth, useWindowSize } from "../../../hooks";
import WarningIcon from "../../../components/Svg/Icons/Warning/WarningIcon";
import EyeIconButton from "../../../components/Svg/Icons/EyeIconButton/EyeIconButton";
import Loading from "../../../components/Loading/Loading";
import * as S from "./style";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signInWithEmailAndPassword } = useAuth();
  const { authContext, setAuthContext } = useAuthContext();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { height } = useWindowSize();

  useEffect(() => {
    if (authContext.isLoggedIn) {
      navigate("/V1/yourAccountIsReady");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, handleChange, getFieldProps, status, touched, values, errors } = useFormik({
    initialValues,
    validate: (values) => helpers.validateEmailPassword(values, t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      signInWithEmailAndPassword(values.email, values.password)
        .then((_) => {
          setAuthContext({ user: { email: values.email }, isLoggedIn: true });
          storage.set("email", values.email);
          setLoading(false);
          navigate("/V1/yourAccountIsReady");
        })
        .catch((err) => {
          setLoading(false);
          setSubmitting(false);
          captureException(err);
          if (err.code === "auth/user-not-found") {
            setStatus(t("login.errors.userNotFound"));
          } else {
            setStatus(t("login.errors.signInDetailsAreIncorrect"));
          }
        });
    },
  });

  return (
    <S.Main heightvalue={height}>
      <S.Form onSubmit={handleSubmit}>
        <S.Header>
          <S.HeaderText>{t("common.auth.signIn")}</S.HeaderText>
          <S.HeaderSubText>
            {t("login.dontHaveAnAccount")} <S.SignInButton onClick={() => navigate("/V1/register")}>{t("common.auth.signUp")}</S.SignInButton>
          </S.HeaderSubText>
        </S.Header>
        <S.Content>
          <S.InputWrapper>
            <S.InputLabel>{t("common.auth.email")}</S.InputLabel>
            <S.Input {...getFieldProps("email")} type="email" error={errors.email ? true : false} name="email" value={values.email} onChange={handleChange} autoComplete="email" />
            {touched.email && errors.email && (
              <S.WarningContent>
                <WarningIcon fill="#20C4E5" />
                <div>{errors.email}</div>
              </S.WarningContent>
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputLabel>{t("common.auth.password")}</S.InputLabel>
            <S.InputContent>
              <S.Input
                type={showPassword ? "text" : "password"}
                error={touched.password && errors.password ? true : false}
                name="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              <EyeIconButton show={showPassword} fill="#fff" clicked={() => setShowPassword(!showPassword)} />
            </S.InputContent>
            {touched.password && errors.password && (
              <S.WarningContent>
                <WarningIcon fill="#20C4E5" />
                <div>{errors.password}</div>
              </S.WarningContent>
            )}
          </S.InputWrapper>
        </S.Content>
        <S.Bottom heightvalue={height}>
          <S.StyledButton type="submit" disabled={!values.email || !values.password}>
            {loading ? <Loading size="1.25rem" /> : t("login.button")}
          </S.StyledButton>
        </S.Bottom>
        {!!status && (
          <S.ErrorContent>
            <WarningIcon fill="#20C4E5" />
            <div>{status}</div>
          </S.ErrorContent>
        )}
      </S.Form>
    </S.Main>
  );
};

export default Login;
