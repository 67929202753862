import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const PayNow = styled.div`
  background: #393939;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
`;

export const PayNowTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const PaymentForm = styled.form`
  color: #232d49;
  position: relative;
  z-index: 999;
`;

export const CustomButton = styled.button<{ disabled: boolean }>`
  width: calc(100% - 1rem);
  margin: 1rem auto;
  border-radius: 2rem;
  height: 3.5rem;
  background: #f05a22;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;

  ${(props) =>
    props.disabled && {
      opacity: 0.7,
      cursor: "unset",
      // background: "#f05a224d !important",
      // color: "rgba(255, 255, 255, 0.8)",
    }}

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
`;

export const myStyles = css`
  background-color: blue;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkblue;
  }
`;
