import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  margin: 1rem auto;
  @media only screen and (min-width: 300px) and (max-width: 389px) {
    gap: 0.5rem;
  }
`;

export const Box = styled(motion.div)<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #532e20;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #ffffff33;
  padding: 0.75rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 7.75rem;
  @media (max-width: 768px) {
    min-width: 5rem;
    font-size: 0.75rem;
  }
  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    min-width: 5rem;
    font-size: 0.675rem;
  }

  @media only screen and (min-width: 300px) and (max-width: 389px) {
    font-size: 0.675rem;
  }
`;
