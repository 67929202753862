import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Packages = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  box-sizing: border-box;
  padding: 0rem 1.5rem;
  gap: 0.5rem;
  color: #fff;
  font-size: 0.875rem;
  position: relative;
`;

export const PackageCard = styled(motion.div)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.25rem;
  border-radius: 1.5rem;
  position: relative;
  background: #171932;
  border: 0.125rem solid #3a3a3a;
  cursor: pointer;
  z-index: 2;

  ${({ selected }) =>
    selected &&
    `

    border: 0.125rem solid #F05A22;
  `}

  @media (max-width: 768px) {
    /* padding: 0.625rem 1.25rem; */
    padding: 1.25rem;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    gap: 0.75rem;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) {
    gap: 1rem;
  }
`;

export const CustomButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  z-index: 6;
  background: transparent;
`;

export const CustomButton = styled(motion.button)`
  width: 23rem;
  border-radius: 2rem;
  height: 3.5rem;
  background: #f05a22;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  margin: 0.5rem 0;
  position: sticky;
  text-transform: unset;
  z-index: 5;
  bottom: 0;
  height: 3.5rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const BestOffer = styled.div`
  display: flex;
  position: absolute;
  top: -0.675rem;
  left: 0;
  right: 0;
  justify-content: center;
  color: #fff;
  background: #f05a22;
  font-weight: 500;
  gap: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.325rem;
  width: 5rem;
  margin: 0 auto;
  align-items: center;
  text-transform: uppercase;
  box-shadow: 0px 4.55px 4.55px 0px #00000040;
`;

export const OfferText = styled.div`
  font-size: 0.625rem;
  font-family: Gill Sans;
  letter-spacing: 0.1px;
`;

export const Period = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TotalPrice = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  font-size: 1.125rem;
`;

export const WeeklyPrice = styled.div`
  display: flex;
  align-items: flex-end;
  color: #ffffff80;
  font-size: 0.875rem;
  font-weight: lighter;
`;

export const Header = styled.div`
  display: flex;
  gap: 0.5rem;
  background: #0000004d;
  padding: 0.75rem;
  border-radius: 0.5rem;
  width: fit-content;
  align-items: center;
`;

export const HeaderBold = styled.span`
  color: #e5fa40;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
`;

export const HeaderThin = styled.span`
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
`;
