import styled from "@emotion/styled";

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24rem;
  background: linear-gradient(0deg, rgba(35, 45, 73, 1) 70%, rgba(0, 187, 97, 0.4) 95%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: rgba(35, 45, 73, 1);
  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    z-index: 1;
    border: 0.0625rem solid #ffffff1a;
    border-radius: 1rem;
  }
`;

export const C = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 7rem;
  position: relative;
`;

export const Moire = styled.div`
  background: rgba(0, 187, 97, 0.1);
  height: 6rem;
  width: 6rem;
  position: absolute;
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 2rem;
`;

export const Title = styled.div`
  color: #fff;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const SubTitle = styled.div`
  color: #8e98b4;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: lighter;
`;

export const CustomButton = styled.button`
  width: 90%;
  border-radius: 1.5rem;
  background: #f05a22;
  text-transform: unset;
  margin: 1.5rem 0;
  height: 3.5rem;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  z-index: 999;
`;
