import styled from "@emotion/styled";
import bgHeaderMap from "../../assets/img/header/bg_header_map.webp";

export const Header = styled.header`
  height: 6rem;
  background: url(${bgHeaderMap});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 500;
  border-image: linear-gradient(94.77deg, #10567c -9.59%, #23dbf5 118.74%);
  border-style: solid;
  border-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  @media (max-width: 768px) {
    height: 0;
  }
`;

export const HeaderBg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const AppLogo = styled.img`
  position: absolute;
  top: 1rem;
  left: 2rem;
  width: 4rem;
`;

export const AppText = styled.div`
  position: absolute;
  top: 1rem;
  left: 7.25rem;
  width: 4rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.01rem;
  z-index: 1;
`;

export const Bold = styled.div`
  font-weight: 700;
`;

export const PinsContainer = styled.div`
  min-width: 30rem;
  height: 6rem;
  display: flex;
  gap: 2rem;
  position: relative;
  justify-content: center;
`;

export const PinMuseum = styled.img`
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  width: 3rem;
  filter: blur(1px);
`;

export const PinEvent = styled.img`
  position: absolute;
  bottom: 0.5rem;
  left: 9rem;
  width: 3rem;
  filter: blur(1px);
`;

export const PinRadar = styled.img`
  position: absolute;
  top: 1rem;
  left: 18rem;
  width: 3rem;
  filter: blur(1px);
`;

export const PinBar = styled.img`
  position: absolute;
  bottom: 0.75rem;
  left: 27rem;
  width: 3rem;
  filter: blur(1px);
`;

export const HomeIcon = styled.img`
  height: 3rem;
  margin-top: 0;
  margin-left: 1rem;

  @media (max-width: 768px) {
    height: 1.75rem;
    margin-left: 0;
  }
`;

export const Content = styled.div`
  margin-top: 6rem;
  height: calc(100vh - 6rem);
  background: #171717;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;

  @media (max-width: 768px) {
    margin-top: 0;
    height: 100vh;
  }
`;

export const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
