import { memo } from "react";
import { TFunction } from "i18next";
import StarsIcon from "../../../../components/Svg/Icons/Stars/StarsIcon";
import Slider from "react-slick";
import * as S from "./style";
import "./CustomReviews.scss";

type Props = {
  t: TFunction<"translation", undefined>;
};

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  centerMode: true,
  rtl: true,
  swipe: false,
  centerPadding: "0",
};

const CustomReviews: React.FC<Props> = memo(({ t }) => {
  const cards = [
    {
      user: t("bestRadarDetectApp.reviews.user1.user"),
      title: t("bestRadarDetectApp.reviews.user1.title"),
      desc: t("bestRadarDetectApp.reviews.user1.description"),
    },
    {
      user: t("bestRadarDetectApp.reviews.user2.user"),
      title: t("bestRadarDetectApp.reviews.user2.title"),
      desc: t("bestRadarDetectApp.reviews.user2.description"),
    },
    {
      user: t("bestRadarDetectApp.reviews.user3.user"),
      title: t("bestRadarDetectApp.reviews.user3.title"),
      desc: t("bestRadarDetectApp.reviews.user3.description"),
    },
    {
      user: t("bestRadarDetectApp.reviews.user4.user"),
      title: t("bestRadarDetectApp.reviews.user4.title"),
      desc: t("bestRadarDetectApp.reviews.user4.description"),
    },
  ];
  return (
    <S.Container>
      <Slider {...settings} className="custom-slider">
        {cards.map((card, index) => (
          <S.ReviewCard key={index}>
            <S.CardHeader>
              <StarsIcon />
              <S.UserName>{card.user}</S.UserName>
            </S.CardHeader>

            <S.CardContent>
              <S.ReviewTitle>{card.title}</S.ReviewTitle>
              <S.ReviewText>{card.desc}</S.ReviewText>
            </S.CardContent>
          </S.ReviewCard>
        ))}
      </Slider>
    </S.Container>
  );
});

export default memo(CustomReviews);
