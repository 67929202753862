import { useState } from "react";
import { useTranslation } from "react-i18next";
import { firebaseService } from "../../../services/firebase.service";
import { useWindowSize } from "../../../hooks";
import { useAlertContext } from "../../../context";
import { feedbackOptions } from "../../../utils/constants";
import CustomButton from "../../../components/CustomButton/CustomButton";
import subtract from "../../../assets/img/howOftenDoYouTravel/subtract.webp";
import * as S from "./style";

type Props = {
  clicked: () => void;
};

const Feedback: React.FC<Props> = ({ clicked }) => {
  const [option, setOption] = useState<string>();

  const { t } = useTranslation();

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const onSelected = (selectedOption: string) => {
    setOption(selectedOption);
  };

  const onClick = () => {
    if (option) {
      firebaseService.logFirebaseFeedback(option);
      clicked();
    } else showAlert(t("common.selectAnOption"), "warning", { vertical: "top", horizontal: "right" });
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Content>
          <S.Title initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 1, ease: "easeOut" }}>
            <div>{t("feedback.title")}</div>
          </S.Title>

          <S.OptionsContent>
            {feedbackOptions.map((o, i) => (
              <S.Option
                key={o}
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: (i + 2) * 0.2, duration: 1, ease: "easeOut" }}
                onClick={() => onSelected(o)}
                selected={option === o ? 1 : 0}
              >
                <S.OptionsText>{t(`feedback.options.${o}`)}</S.OptionsText>
                <S.OptionSelected>{option === o && <S.SelectedIcon src={subtract} />}</S.OptionSelected>
              </S.Option>
            ))}
          </S.OptionsContent>
        </S.Content>
        <CustomButton width={height / 2} text="Cancel" customDisabled={option === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default Feedback;
