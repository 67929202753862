import styled from "@emotion/styled";
import bgMap from "../../../assets/img/auth/bg_map.webp";

export const Main = styled.div<{ heightvalue: number }>`
  position: relative;
  width: ${(props) => `${props.heightvalue / 2}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow: hidden;
  touch-action: none;
  user-select: none;

  @media (max-width: 768px) {
    width: 90%;
    margin: 1.5rem auto 0;
  }
`;

export const Header = styled.div`
  background: url(${bgMap}), linear-gradient(94.77deg, #10567c -9.59%, #23dbf5 118.74%);
  padding: 1rem 0;
  color: #fff;
  margin: 1rem 0;
  width: 100%;
  border-radius: 0.75rem;
`;

export const HeaderText = styled.div`
  font-size: 1.75rem;
  padding: 0 1rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
`;

export const HeaderSubText = styled.div`
  width: 100%;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #282828;
  padding: 1rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
`;

export const Bottom = styled.div<{ heightvalue: number }>`
  width: ${(props) => `${props.heightvalue / 2}px`};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
  border-radius: 1.5rem;
  height: 3rem;
  background: #f05a22;
  color: #fff;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  text-transform: unset;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: unset;
  border: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignUpButton = styled.button`
  text-transform: unset;
  color: #fff;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  min-width: unset;
  background-color: unset;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    background: none;
  }
`;

export const InputWrapper = styled.div`
  width: 90%;
  margin: 0.5rem 0;
`;

export const InputContent = styled.div`
  position: relative;
`;

export const Input = styled.input<{ error: boolean }>`
  color: #fff !important;
  width: -webkit-fill-available;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  border-color: ${(props) => (props.error ? "#F02222" : "#9B9B9B")};
  border-style: solid !important;
  outline: unset !important;
  outline-offset: 0 !important;
  padding: 1rem !important;
  background-color: ${(props) => (props.error ? "#F022221A" : "#282828")} !important;
  border-radius: 0.25rem;

  &:focus-visible {
    outline: unset !important;
    outline-offset: 0;
    background-color: ${(props) => (props.error ? "#F022221A" : "#f05a221a")} !important;
    border-color: ${(props) => (props.error ? "#F02222" : "#f05a22")};
    border-style: solid !important;
  }
`;

export const InputLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
  text-transform: unset;
  color: #fff;
  margin-bottom: 0.5rem;
`;

export const WarningContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  gap: 0.5rem;
  color: #9b9b9b;
  margin-top: 0.5rem;
`;

export const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.875rem;
  gap: 0.5rem;
  color: #f02222;
`;

export const Form = styled.form`
  width: 100%;
`;
