import React, { memo } from "react";
import * as S from "./style";

type Props = {
  step: number;
};

const CustomProgressBar: React.FC<Props> = ({ step }) => {
  const totalSteps = 5;
  const percentage = (step / totalSteps) * 100;

  return (
    <S.ProgressContainer>
      <S.ProgressBar style={{ width: `${percentage}%` }}></S.ProgressBar>
    </S.ProgressContainer>
  );
};

export default memo(CustomProgressBar);
