import { memo, useState } from "react";
import { motion } from "framer-motion";
import "./style.scss";

type Props = {
  img: string;
  alt: string;
  height?: string;
  width?: string;
  onLoad?: (loaded: boolean) => void;
};

const LazyLoadImage: React.FC<Props> = ({ img, alt, height, width, onLoad }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
    setTimeout(() => {
      setPulsing(false);
      if (onLoad) onLoad(true);
    }, 500);
  };

  return (
    <div className={`${pulsing ? "pulse" : ""} loadable`} style={{ background: "transparent" }}>
      <motion.img
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay: 0.5, duration: 0.4 } }}
        onLoad={imageLoaded}
        height={height}
        width={width}
        src={img}
        alt={alt}
      />
    </div>
  );
};

export default memo(LazyLoadImage);
