import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 16rem;
  position: relative;

  @media (max-width: 768px) {
    height: 16rem;
  }
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    height: 19rem;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) {
    height: 19rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 30rem;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    height: 35rem;
  }
`;

export const Image = styled.div`
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  gap: 1rem;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
    gap: 0.75rem;
  }
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    margin-bottom: 1rem;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) {
    margin-bottom: 2rem;
  }
`;

export const Title = styled(motion.div)`
  text-align: start;
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 2rem;
  color: #fff;
  user-select: none;
  text-align: center;
`;

export const SubTitle = styled(motion.div)`
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  user-select: none;
  color: #ffffff9c;
  /* padding: 0 3.5rem; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const CustomButtonContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  width: calc(100% - 2rem);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  @media (max-width: 768px) {
    left: 1rem;
    right: 1rem;
    width: calc(100% - 2rem);
  }
`;

export const CustomButton = styled.button`
  width: 24rem;
  border-radius: 2rem;
  height: 3rem;
  background: #e5fa40;
  color: #232d49;
  text-transform: none;
  font-weight: 500;
  &:disabled {
    background: #899628 !important;
    color: #232d49 !important;
  }
  &:hover {
    background: #e5fa40 !important;
  }
  @media (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

export const TextButtonsContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0.5rem;
  right: 0.5rem;
  width: calc(100% - 1rem);
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;

  @media (max-width: 768px) {
    bottom: 0.25rem;
    left: 1rem;
    right: 1rem;
    width: calc(100% - 2rem);
  }
`;

export const TextButton = styled("a")`
  border-radius: 2rem;
  color: #fff;
  opacity: 0.6;
  text-transform: none;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  align-content: flex-end;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
    /* background: #E5FA40 !important; */
  }
`;
