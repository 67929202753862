import { TranslationKeys } from "../../model";

const pt: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Oi, com que frequência você viaja?",
      scanning: "Digitalizando",
      options: {
        Always: "Sempre",
        Mostly: "Na maioria das vezes",
        Occasionally: "Ocasionalmente",
        Never: "Nunca",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Você já recebeu uma multa de trânsito antes?",
      policeControl: "Controle policial",
      options: {
        Yes: "Sim",
        "No tickets so far": "Não",
      },
    },
    didYouKnow: {
      title: "Você sabe sobre multas de radar?",
      pattern: "Você Sabia",
      description:
        "Os governos arrecadam 6,2 bilhões anualmente (cidade, condado ou estado). Isso representa 30% de todas as multas de trânsito emitidas anualmente em todo o mundo.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Você gostaria de ver os radares na navegação?",
      options: {
        Yes: "Sim",
        No: "Não",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Deixe as multas de radar para trás com nosso aplicativo",
      options: {
        safeZone: "Zona Segura",
        niceTrip: "Boa Viagem",
        safeMoney: "Economize Dinheiro",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Confiável com uma média de 4,6",
      secondTitle: "Mais de 2000 avaliações!",
      content: {
        best: "O melhor",
        radarDetect: "Deteção de Radar",
        app: "Implementação",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Altamente recomendável!",
          description: "A função de detecção de radar é incrivelmente precisa e confiável. Ela me salvou de me perder e me ajudou a encontrar algumas jóias escondidas incríveis.",
        },
        user2: {
          user: "Amelia R.",
          title: "Definitivamente vale cinco estrelas!",
          description: "Adoro! O radar funciona perfeitamente e tem sido um salva-vidas em várias ocasiões. É intuitivo, fácil de usar e muito preciso.",
        },
        user3: {
          user: "SarahM123",
          title: "Uau!",
          description:
            "Tentei vários aplicativos de navegação, mas este é o único que cumpre consistentemente sua promessa de detecção confiável de radar. A interface é amigável.",
        },
        user4: {
          user: "Emma J.",
          title: "Ótimo aplicativo!",
          description:
            "Ele me ajudou a encontrar as melhores rotas, evitar o trânsito e descobrir jóias escondidas ao longo do caminho. Os mapas offline são uma salvação e a navegação por voz é clara e concisa.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Sua conta está pronta!",
      description: "Agora salve seu plano personalizado configurando uma conta",
    },
    plan: {
      title: "Detecte todos os sistemas de radar antes que eles detectem você!",
      description: "Sinta-se seguro contra multas graças a mapas atualizados e alertas de radar.",
      mostPopular: "MAIS POPULAR",
      payment: {
        billingDetails: "Detalhes de Cobrança",
        payNowWith: "Pagar agora com",
        yourPaymentisSecured: {
          title: "Seu pagamento está seguro",
          description: "O pagamento é processado pela GM Appdev Limited, com endereço registrado em Nafpliou, 15, Limassol, Chipre, 3025",
        },
        success: {
          title: "Pagamento Bem Sucedido",
          description: "Pagamento realizado com sucesso. Você será redirecionado para o aplicativo.",
        },
        failed: {
          title: "Pagamento Falhou",
          description: "Ocorreu um erro durante o pagamento. Clique no botão para tentar novamente.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Não tem uma conta?",
      errors: {
        userNotFound: "Usuário não encontrado. Por favor, entre",
        signInDetailsAreIncorrect: "Os detalhes de login estão incorretos",
      },
      button: "Entrar",
    },
    register: {
      alreadyHaveAnAccount: "Já tem uma conta?",
      errors: {
        emailAlreadyInUse: "E-mail já está em uso. Por favor, entre",
        registrationInDetailsAreIncorrect: "Os detalhes de registro estão incorretos",
      },
      button: "Criar Conta",
    },
    feedback: {
      title: "Por que você decidiu cancelar?",
      options: {
        "Not Enough Time to Use the App": "Não tenho tempo suficiente para usar o aplicativo",
        "Interested in Other Apps": "Interessado em outros aplicativos",
        "Doesn't Meet My Needs": "Não atende às minhas necessidades",
        "Lacks Motivation": "Falta de motivação",
        "Not User-Friendly": "Não é amigável",
        "Too Expensive": "Muito caro",
        Other: "Outro",
      },
    },
    paymentCancel: {
      title: "Cancelar Pagamento",
      errors: {
        somethingWentWrong: "Algo deu errado.",
        invalidCredential: "Usuário não encontrado",
      },
      success: {
        title: "Cancelado com Sucesso",
      },
      failed: {
        title: "Cancelamento Falhou",
        description: "Ocorreu um erro durante o cancelamento.",
      },
    },
    logOut: {
      title: "Redirecionando para a página de login...",
    },
    notFound: {
      title: "Página não encontrada.",
      description: "Redirecionando para a página de boas-vindas...",
    },
    common: {
      continue: "Continuar",
      getPlan: "Obter Plano",
      payNow: "Pagar Agora",
      tryAgain: "Tentar Novamente",
      goToApp: "Ir para o Aplicativo",
      cancel: "Cancelar",
      privacy: "Política de Privacidade",
      terms: "Termos de Uso",
      timeUnits: {
        year: "Ano",
        month: "Mês",
        week: "Semana",
      },
      billing: {
        year: "1 Ano",
        month: "1 Mês",
        week: "1 Semana",
        total: "Total",
      },
      then: "Então",
      auth: {
        email: "E-mail",
        password: "Senha",
        signIn: "Entrar",
        signUp: "Cadastrar-se",
        errors: {
          emailIsRequired: "E-mail é obrigatório",
          enterValidEmailAddress: "Por favor, insira um endereço de e-mail válido",
          passwordIsRequired: "Senha é obrigatória",
          passwordMinChar: "Mínimo de 8 caracteres",
        },
      },
      selectAnOption: "Selecione uma Opção",
    },
  },
};

export default pt;
