/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from "react";
import * as S from "./style";

type EyeIconButtonProps = {
  clicked: () => void;
  show: boolean;
  fill?: string;
  width?: string;
  height?: string;
};

const EyeIconButton: React.FC<EyeIconButtonProps> = ({ clicked, show, fill = "rgba(36, 36, 36, 0.5)", width = "2.5rem", height = "1rem" }) => (
  <>
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        clicked();
      }}
      onKeyDown={() => {}}
    >
      <S.EyeIconButtonWrapper>
        <S.EyeIconSvg viewBox="0 0 24 24" width={width} height={height}>
          {show ? (
            <g>
              <path
                d="M11.9999 3C17.3919 3 21.8779 6.88 22.8189 12C21.8789 17.12 17.3919 21 11.9999 21C6.60791 21 2.12191 17.12 1.18091 12C2.12091 6.88 6.60791 3 11.9999 3ZM11.9999 19C14.0394 18.9996 16.0183 18.3068 17.6128 17.0352C19.2072 15.7635 20.3228 13.9883 20.7769 12C20.3211 10.0133 19.2048 8.24 17.6105 6.97003C16.0162 5.70005 14.0382 5.00853 11.9999 5.00853C9.96161 5.00853 7.9836 5.70005 6.38928 6.97003C4.79497 8.24 3.67868 10.0133 3.22291 12C3.677 13.9883 4.79258 15.7635 6.38705 17.0352C7.98152 18.3068 9.96044 18.9996 11.9999 19ZM11.9999 16.5C10.8064 16.5 9.66184 16.0259 8.81793 15.182C7.97401 14.3381 7.49991 13.1935 7.49991 12C7.49991 10.8065 7.97401 9.66193 8.81793 8.81802C9.66184 7.97411 10.8064 7.5 11.9999 7.5C13.1934 7.5 14.338 7.97411 15.1819 8.81802C16.0258 9.66193 16.4999 10.8065 16.4999 12C16.4999 13.1935 16.0258 14.3381 15.1819 15.182C14.338 16.0259 13.1934 16.5 11.9999 16.5ZM11.9999 14.5C12.6629 14.5 13.2988 14.2366 13.7677 13.7678C14.2365 13.2989 14.4999 12.663 14.4999 12C14.4999 11.337 14.2365 10.7011 13.7677 10.2322C13.2988 9.76339 12.6629 9.5 11.9999 9.5C11.3369 9.5 10.701 9.76339 10.2321 10.2322C9.7633 10.7011 9.49991 11.337 9.49991 12C9.49991 12.663 9.7633 13.2989 10.2321 13.7678C10.701 14.2366 11.3369 14.5 11.9999 14.5Z"
                fill={fill}
              />
            </g>
          ) : (
            <g>
              <path
                d="M9.34202 18.782L7.41103 18.264L8.19803 15.325C7.01999 14.8905 5.92514 14.2573 4.96103 13.453L2.80803 15.607L1.39303 14.192L3.54703 12.039C2.3311 10.5827 1.51411 8.83575 1.17603 6.96899L3.14403 6.60999C3.90303 10.812 7.57903 14 12 14C16.42 14 20.097 10.812 20.856 6.60999L22.824 6.96799C22.4864 8.835 21.6697 10.5824 20.454 12.039L22.607 14.192L21.192 15.607L19.039 13.453C18.0749 14.2573 16.9801 14.8905 15.802 15.325L16.589 18.265L14.658 18.782L13.87 15.842C12.6324 16.0541 11.3677 16.0541 10.13 15.842L9.34202 18.782Z"
                fill={fill}
              />
            </g>
          )}
        </S.EyeIconSvg>
      </S.EyeIconButtonWrapper>
    </div>
  </>
);

export default memo(EyeIconButton);
