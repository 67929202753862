import { memo } from "react";
import Loading from "../Loading/Loading";
import * as S from "./style";

const AppLoading = () => (
  <S.Main>
    <Loading size="2rem" color="#F6795866" />
  </S.Main>
);

export default memo(AppLoading);
