import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { TPage } from "../../../model";
import { firebaseService } from "../../../services/firebase.service";
import { fbq } from "../../../services/metapixel.service";
import { useAuth, useNextStep, useWindowSize } from "../../../hooks";
import { captureException } from "../../../services/sentry.service";
import { helpers, storage } from "../../../utils";
import { FirebaseError } from "@firebase/util";
import { useAuthContext } from "../../../context";
import WarningIcon from "../../../components/Svg/Icons/Warning/WarningIcon";
import EyeIconButton from "../../../components/Svg/Icons/EyeIconButton/EyeIconButton";
import Loading from "../../../components/Loading/Loading";
import * as S from "./style";

const initialValues = {
  email: "",
  password: "",
};

const Register: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleContinue = useNextStep({ pageId, nextPagePath });
  const navigate = useNavigate();
  const { height } = useWindowSize();

  const { createUserWithEmailAndPassword } = useAuth();
  const { setAuthContext } = useAuthContext();

  const { t } = useTranslation();

  const { handleSubmit, handleChange, status, getFieldProps, touched, values, errors } = useFormik({
    initialValues,
    validate: (values) => helpers.validateEmailPassword(values, t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      firebaseService.logFirebaseEvent("register_save_button_clicked");
      fbq("trackCustom", "register_save_button_clicked");

      storage.set("email", values.email);

      createUserWithEmailAndPassword(values.email, values.password)
        .then((_) => {
          setAuthContext({ user: { email: values.email }, isLoggedIn: true });
          handleContinue(values.email);
          setLoading(false);
        })
        .catch((err: FirebaseError) => {
          setLoading(false);
          setSubmitting(false);
          captureException(err);
          if (err.code === "auth/email-already-in-use") {
            setStatus(t("register.errors.emailAlreadyInUse"));
          } else {
            setStatus(t("register.errors.registrationInDetailsAreIncorrect"));
          }
        });
    },
  });

  return (
    <S.Main heightvalue={height}>
      <S.Form onSubmit={handleSubmit}>
        <S.Header>
          <S.HeaderText>{t("common.auth.signUp")}</S.HeaderText>
          <S.HeaderSubText>
            <span>{t("register.alreadyHaveAnAccount")}</span>
            <S.SignUpButton onClick={() => navigate("/V1/login")}>{t("common.auth.signIn")}</S.SignUpButton>
          </S.HeaderSubText>
        </S.Header>
        <S.Content>
          <S.InputWrapper>
            <S.InputLabel>{t("common.auth.email")}</S.InputLabel>
            <S.Input {...getFieldProps("email")} type="email" error={errors.email ? true : false} name="email" value={values.email} onChange={handleChange} autoComplete="email" />
            {touched.email && errors.email ? (
              <S.WarningContent>
                <WarningIcon fill="#20C4E5" />
                <div>{errors.email}</div>
              </S.WarningContent>
            ) : null}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputLabel>{t("common.auth.password")}</S.InputLabel>
            <S.InputContent>
              <S.Input
                type={showPassword ? "text" : "password"}
                error={touched.password && errors.password ? true : false}
                name="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              <EyeIconButton show={showPassword} fill="#fff" clicked={() => setShowPassword(!showPassword)} />
            </S.InputContent>
            {touched.password && errors.password ? (
              <S.WarningContent>
                <WarningIcon fill="#20C4E5" />
                <div>{errors.password}</div>
              </S.WarningContent>
            ) : null}
          </S.InputWrapper>
        </S.Content>
        <S.Bottom heightvalue={height}>
          <S.StyledButton type="submit" disabled={!values.email || !values.password}>
            {loading ? <Loading size="1.25rem" /> : t("register.button")}
          </S.StyledButton>
        </S.Bottom>
        {!!status ? (
          <S.ErrorContent>
            <WarningIcon fill="#20C4E5" />
            <div>
              <div>{status}</div>
            </div>
          </S.ErrorContent>
        ) : null}
      </S.Form>
    </S.Main>
  );
};

export default Register;
