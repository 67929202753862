import { FEEDBACK_OPTION } from "../enum";

const feedbackOptions = [
  FEEDBACK_OPTION.NOT_ENOUGH_TIME_TO_USE_THE_APP,
  FEEDBACK_OPTION.INTERESTED_IN_OTHER_APPS,
  FEEDBACK_OPTION.DOESNT_MEET_MY_NEEDS,
  FEEDBACK_OPTION.LACKS_MOTIVATION,
  FEEDBACK_OPTION.NOT_USER_FRIENDLY,
  FEEDBACK_OPTION.TOO_EXPENSIVE,
  FEEDBACK_OPTION.OTHER,
];

export default feedbackOptions;
