import { Cohort } from "../utils/constants/rootConstants";
import { PAGE_ID } from "../utils/enum";
import HowOftenDoYouTravel from "../pages/Cohort1/HowOftenDoYouTravel/HowOftenDoYouTravel";
import HaveYouEverReceivedATrafficTicketBefore from "../pages/Cohort1/HaveYouEverReceivedATrafficTicketBefore/HaveYouEverReceivedATrafficTicketBefore";
import DidYouKnow from "../pages/Cohort1/DidYouKnow/DidYouKnow";
import WouldYouLikeToSeeTheRadarsOnTheNavigation from "../pages/Cohort1/WouldYouLikeToSeeTheRadarsOnTheNavigation/WouldYouLikeToSeeTheRadarsOnTheNavigation";
import LeaveRadarFinesBehindWithOurApp from "../pages/Cohort1/LeaveRadarFinesBehindWithOurApp/LeaveRadarFinesBehindWithOurApp";
import BestRadarDetectApp from "../pages/Cohort1/BestRadarDetectApp/BestRadarDetectApp";
import Register from "../pages/Cohort1/Register/Register";
import YourAccountIsReady from "../pages/Cohort1/YourAccountIsReady/YourAccountIsReady";
import Plan from "../pages/Cohort1/Plan/Plan";

export const ID_TO_PAGE_MAP = {
  howOftenDoYouTravel: HowOftenDoYouTravel,
  haveYouEverReceivedATrafficTicketBefore: HaveYouEverReceivedATrafficTicketBefore,
  didYouKnow: DidYouKnow,
  wouldYouLikeToSeeTheRadarsOnTheNavigation: WouldYouLikeToSeeTheRadarsOnTheNavigation,
  leaveRadarFinesBehindWithOurApp: LeaveRadarFinesBehindWithOurApp,
  bestRadarDetectApp: BestRadarDetectApp,
  register: Register,
  yourAccountIsReady: YourAccountIsReady,
  plan: Plan,
};

export const V1: { id: PAGE_ID }[] = [
  { id: PAGE_ID.HOW_OFTEN_DO_YOU_TRAVEL },
  { id: PAGE_ID.HAVE_YOU_EVER_RECEIVED_A_TRAFFIC_TICKET_BEFORE },
  { id: PAGE_ID.DID_YOU_KNOW },
  { id: PAGE_ID.WOULD_YOU_LIKE_TO_SEE_THE_RADARS_ON_THE_NAVIGATION },
  { id: PAGE_ID.LEAVE_RADAR_FINES_BEHIND_WITH_OUR_APP },
  { id: PAGE_ID.BEST_RADAR_DETECT_APP },
  { id: PAGE_ID.REGISTER },
  { id: PAGE_ID.YOUR_ACCOUNT_IS_READY },
  { id: PAGE_ID.PLAN },
];

export const DYNAMIC_PAGES_MAP: Record<Cohort, { id: PAGE_ID }[]> = {
  [Cohort.V1]: V1,
};
