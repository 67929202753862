import styled from "@emotion/styled";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
export const StyledModal = styled(CustomModal)`
  width: 100%;
  top: 5rem;
  border: none !important;
  left: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: 0;
  bottom: 5rem;
  @media (max-width: 768px) {
    top: 2.5rem;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #232d49 !important;
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Content = styled.div<{ height: number }>`
  width: ${(props) => `${props.height / 2}px`};
  background-color: #282828;
  padding: 1rem;
  border-radius: 1.375rem;
  margin-top: 8.5rem;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin-top: 3rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: #393939;
  padding: 2rem 1rem;
  color: #fff;
  border-radius: 0.75rem;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #393939;
  border-radius: 0.75rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const Hr = styled.div`
  border-color: #414c6d;
  margin: 0.5rem 0;
  border-width: 0.0625rem;
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  background: #393939;
  border-radius: 0.75rem;
`;

export const BottomTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 1rem 0 1rem;
  color: #fff;
`;

export const BankCards = styled.img`
  width: 80%;
  margin: 1rem auto;
`;

export const BottomDesc = styled.div`
  color: #ffffffb2;
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 0.5rem 1rem 0.5rem;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 5.5rem;
  right: 1.625rem;
  width: 50%;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 0rem;
    right: -3.75rem;
    width: 50%;
  }
`;
