import { TranslationKeys } from "../../model";

const ru: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Привет, как часто вы путешествуете?",
      scanning: "Сканирование",
      options: {
        Always: "Всегда",
        Mostly: "Чаще всего",
        Occasionally: "Иногда",
        Never: "Никогда",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Вы когда-нибудь получали штраф за нарушение ПДД?",
      policeControl: "Полицейский контроль",
      options: {
        Yes: "Да",
        "No tickets so far": "Нет",
      },
    },
    didYouKnow: {
      title: "Знаете ли вы о штрафах за превышение скорости?",
      pattern: "Знаете ли вы",
      description: "Правительства ежегодно собирают 6,2 миллиарда долларов (город, округ или штат). Это 30% всех мировых штрафов за нарушение ПДД, выписанных ежегодно.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Хотите видеть радары на навигационной карте?",
      options: {
        Yes: "Да",
        No: "Нет",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Избегайте штрафов с нашим приложением",
      options: {
        safeZone: "Безопасная зона",
        niceTrip: "Приятная поездка",
        safeMoney: "Экономия денег",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Доверие с оценкой 4.6",
      secondTitle: "Более 2000 отзывов!",
      content: {
        best: "Лучшее",
        radarDetect: "Обнаружение радаров",
        app: "Реализация",
      },
      reviews: {
        user1: {
          user: "Майк Б.",
          title: "Очень рекомендую!",
          description: "Функция обнаружения радаров невероятно точная и надежная. Она спасла меня от заблуждения и помогла найти удивительные скрытые жемчужины.",
        },
        user2: {
          user: "Амелия Р.",
          title: "Определенно заслуживает пять звезд!",
          description: "Абсолютно люблю это! Радар работает безупречно и не раз спасал меня. Он интуитивно понятен, прост в использовании и очень точен.",
        },
        user3: {
          user: "Сара М.",
          title: "Вау!",
          description:
            "Я пробовала несколько навигационных приложений, но это единственное, которое постоянно выполняет свое обещание надежного обнаружения радаров. Интерфейс удобный.",
        },
        user4: {
          user: "Эмма Дж.",
          title: "Отличное приложение!",
          description:
            "Оно помогло мне найти лучшие маршруты, избежать пробок и открыть для себя скрытые жемчужины на пути. Офлайн-карты спасают, а голосовая навигация четкая и точная.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Ваш аккаунт готов!",
      description: "Теперь сохраните свой персонализированный план, создав аккаунт",
    },
    plan: {
      title: "Обнаруживайте все радары до того, как они обнаружат вас!",
      description: "Чувствуйте себя в безопасности от штрафов благодаря актуальным картам и оповещениям о радарах.",
      mostPopular: "САМЫЙ ПОПУЛЯРНЫЙ",
      payment: {
        billingDetails: "Платежные данные",
        payNowWith: "Оплатить сейчас с помощью",
        yourPaymentisSecured: {
          title: "Ваш платеж защищен",
          description: "Платеж обрабатывается компанией GM Appdev Limited, зарегистрированной по адресу: Нафплиу, 15, Лимассол, Кипр, 3025",
        },
        success: {
          title: "Оплата успешна",
          description: "Оплата успешно произведена. Вы перенаправлены в приложение.",
        },
        failed: {
          title: "Ошибка оплаты",
          description: "Произошла ошибка при оплате. Нажмите кнопку, чтобы попробовать снова.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Нет аккаунта?",
      errors: {
        userNotFound: "Пользователь не найден. Пожалуйста, войдите",
        signInDetailsAreIncorrect: "Данные для входа неверны",
      },
      button: "Войти",
    },
    register: {
      alreadyHaveAnAccount: "Уже есть аккаунт?",
      errors: {
        emailAlreadyInUse: "Электронная почта уже используется. Пожалуйста, войдите",
        registrationInDetailsAreIncorrect: "Данные для регистрации неверны",
      },
      button: "Создать аккаунт",
    },
    feedback: {
      title: "Почему вы решили отменить?",
      options: {
        "Not Enough Time to Use the App": "Недостаточно времени для использования приложения",
        "Interested in Other Apps": "Интересуют другие приложения",
        "Doesn't Meet My Needs": "Не соответствует моим потребностям",
        "Lacks Motivation": "Нет мотивации",
        "Not User-Friendly": "Неудобно в использовании",
        "Too Expensive": "Слишком дорого",
        Other: "Другое",
      },
    },
    paymentCancel: {
      title: "Отмена платежа",
      errors: {
        somethingWentWrong: "Что-то пошло не так.",
        invalidCredential: "Пользователь не найден",
      },
      success: {
        title: "Успешно отменено",
      },
      failed: {
        title: "Ошибка отмены",
        description: "Произошла ошибка при отмене.",
      },
    },
    logOut: {
      title: "Перенаправление на страницу входа...",
    },
    notFound: {
      title: "Страница не найдена.",
      description: "Перенаправление на страницу приветствия...",
    },
    common: {
      continue: "Продолжить",
      getPlan: "Получить план",
      payNow: "Оплатить сейчас",
      tryAgain: "Попробовать снова",
      goToApp: "Перейти к приложению",
      cancel: "Отмена",
      privacy: "Политика конфиденциальности",
      terms: "Условия использования",
      timeUnits: {
        year: "Год",
        month: "Месяц",
        week: "Неделя",
      },
      billing: {
        year: "1 Год",
        month: "1 Месяц",
        week: "1 Неделя",
        total: "Итого",
      },
      then: "Затем",
      auth: {
        email: "Эл. почта",
        password: "Пароль",
        signIn: "Войти",
        signUp: "Зарегистрироваться",
        errors: {
          emailIsRequired: "Требуется эл. почта",
          enterValidEmailAddress: "Введите действующий адрес эл. почты",
          passwordIsRequired: "Требуется пароль",
          passwordMinChar: "Минимум 8 символов",
        },
      },
      selectAnOption: "Выберите вариант",
    },
  },
};

export default ru;
