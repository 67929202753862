import { memo, useState, useRef /*, useEffect */ } from "react";
import ReactPlayer from "react-player/lazy";
import { useNextStep, useIsMobile, useWindowSize } from "../../../hooks";
import { useAlertContext } from "../../../context";
import { wouldYouLikeToSeeTheRadarsOnTheNavigationOptions } from "../../../utils/constants";
import { TPage } from "../../../model";
import videoSrc from "../../../assets/video/1.mp4";
import subtract from "../../../assets/img/howOftenDoYouTravel/subtract.webp";
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import { splitText } from "../../../utils/helpers";
import * as S from "./style";

const WouldYouLikeToSeeTheRadarsOnTheNavigation: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [firstLine, secondLine] = splitText(t("wouldYouLikeToSeeTheRadarsOnTheNavigation.title"));

  const videoRef = useRef<any>(null);

  const [option, setOption] = useState<string>();

  const { showAlert } = useAlertContext();

  const isMobile = useIsMobile();

  const { height } = useWindowSize();

  // useEffect(() => {
  //   if (videoRef.current) {
  //     const timeout = setTimeout(() => {
  //       videoRef.current.play();
  //     }, 1000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, []);

  const onSelected = (selectedOption: string) => {
    setOption(selectedOption);
  };

  const onClick = () => {
    if (option) {
      handleContinue(option);
    } else {
      showAlert(t("common.selectAnOption"), "warning", { vertical: "top", horizontal: "right" });
    }
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <ReactPlayer ref={videoRef} url={videoSrc} width={isMobile ? "100%" : height / 2} height="auto" playsinline muted playing />
        {/* <video ref={videoRef} src={videoSrc} width={isMobile ? "100%" : height / 2} autoPlay muted playsInline /> */}
        <S.Content>
          <CustomProgressBar step={4} />

          <S.Title>
            <S.HeaderText initial={{ y: -25, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0, duration: 1, ease: "easeOut" }}>
              <span>{firstLine}</span>
              <br />
              <span>{secondLine}</span>
            </S.HeaderText>
          </S.Title>

          <S.OptionsContent>
            {wouldYouLikeToSeeTheRadarsOnTheNavigationOptions.map((o, i) => (
              <S.Option
                key={o.id}
                initial={{ x: i % 2 === 0 ? 100 : -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: (i + 2) * 0.2, duration: 1, ease: "easeOut" }}
                onClick={() => onSelected(o.label)}
                selected={option === o.label ? 1 : 0}
              >
                <S.OptionIcon>{o.img}</S.OptionIcon>
                <S.OptionsText>{t(`wouldYouLikeToSeeTheRadarsOnTheNavigation.options.${o.label}`)}</S.OptionsText>
                <S.OptionSelected>{option === o.label && <S.SelectedIcon src={subtract} />}</S.OptionSelected>
              </S.Option>
            ))}
          </S.OptionsContent>
        </S.Content>
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={option === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default WouldYouLikeToSeeTheRadarsOnTheNavigation;
