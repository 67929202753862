import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { useAlertContext } from "../../../context";
import { haveYouEverReceivedATrafficTicketBeforeOptions } from "../../../utils/constants";
import img from "../../../assets/img/haveYouEverReceivedATrafficTicketBefore/radar.webp";
import subtract from "../../../assets/img/howOftenDoYouTravel/subtract.webp";
import speed from "../../../assets/img/haveYouEverReceivedATrafficTicketBefore/speed.webp";
import square from "../../../assets/img/haveYouEverReceivedATrafficTicketBefore/square.webp";
import LazyLoadImage from "../../../components/LazyLoadImage/LazyLoadImage";
import CustomProgressBar from "../../../components/CustomProgressBar/CustomProgressBar";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { splitText } from "../../../utils/helpers";
import * as S from "./style";

const HaveYouEverReceivedATrafficTicketBefore: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { t } = useTranslation();

  const [firstLine, secondLine] = splitText(t("haveYouEverReceivedATrafficTicketBefore.title"));

  const [option, setOption] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);

  const { showAlert } = useAlertContext();

  const { height } = useWindowSize();

  const onSelected = (selectedOption: string) => {
    setOption(selectedOption);
  };

  const onClick = () => {
    if (option) {
      handleContinue(option);
    } else {
      showAlert(t("common.selectAnOption"), "warning", { vertical: "top", horizontal: "right" });
    }
  };

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Image
          initial={{ opacity: 0 }}
          animate={{
            opacity: imageLoaded ? 1 : 0,
          }}
          transition={{ opacity: { delay: 0, duration: 0.4 } }}
        >
          <LazyLoadImage img={img} onLoad={(loaded) => setImageLoaded(loaded)} alt="" />
        </S.Image>

        <S.Content>
          <S.Overlay>
            <S.Speed
              src={speed}
              initial={{ y: 100, opacity: 0 }}
              animate={isElementsVisible ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            />
            <S.Square
              src={square}
              initial={{ opacity: 0 }}
              animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            />
            <S.PoliceControl
              initial={{ opacity: 0 }}
              animate={isElementsVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={isElementsVisible ? { delay: 1, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <S.Dot />
              {t("haveYouEverReceivedATrafficTicketBefore.policeControl")}
            </S.PoliceControl>
          </S.Overlay>

          {isElementsVisible && <CustomProgressBar step={2} />}

          <S.Title>
            <S.HeaderText
              initial={{ y: -25, opacity: 0 }}
              animate={isElementsVisible ? { y: 0, opacity: 1 } : { y: -25, opacity: 0 }}
              transition={isElementsVisible ? { delay: 0, duration: 1, ease: "easeOut" } : { delay: 1000 }}
            >
              <span>{firstLine}</span>
              <br />
              <span>{secondLine}</span>
            </S.HeaderText>
          </S.Title>

          <S.OptionsContent>
            {haveYouEverReceivedATrafficTicketBeforeOptions.map((o, i) => (
              <S.Option
                key={o.id}
                initial={{ x: i % 2 === 0 ? 100 : -100, opacity: 0 }}
                animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: i % 2 === 0 ? 100 : -100, opacity: 0 }}
                transition={isElementsVisible ? { delay: (i + 1) * 0.2, duration: 1, ease: "easeOut" } : { delay: 1000 }}
                onClick={() => onSelected(o.label)}
                selected={option === o.label ? 1 : 0}
              >
                <S.OptionTextBody>
                  <S.OptionIcon>{o.img}</S.OptionIcon>
                  <S.OptionsText>{t(`haveYouEverReceivedATrafficTicketBefore.options.${o.label}`)}</S.OptionsText>
                </S.OptionTextBody>
                <S.OptionSelected>{option === o.label && <S.SelectedIcon src={subtract} />}</S.OptionSelected>
              </S.Option>
            ))}
          </S.OptionsContent>
        </S.Content>
        <CustomButton width={height / 2} text={t("common.continue")} customDisabled={option === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
});

export default HaveYouEverReceivedATrafficTicketBefore;
