import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPageInfo } from "../../hooks";
import { Cohort } from "../../utils/constants/rootConstants";
import * as S from "./style";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { currentCohort } = useGetPageInfo();

  const { t } = useTranslation();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (currentCohort === Cohort.V1) {
        navigate("/V1/howOftenDoYouTravel");
      }
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [currentCohort, navigate]);

  return (
    <S.Main>
      <div>{t("notFound.title")}</div>
      <div>{t("notFound.description")}</div>
    </S.Main>
  );
};

export default NotFoundPage;
