const enum FEEDBACK_OPTION {
  NOT_ENOUGH_TIME_TO_USE_THE_APP = "Not Enough Time to Use the App",
  INTERESTED_IN_OTHER_APPS = "Interested in Other Apps",
  DOESNT_MEET_MY_NEEDS = "Doesn't Meet My Needs",
  LACKS_MOTIVATION = "Lacks Motivation",
  NOT_USER_FRIENDLY = "Not User-Friendly",
  TOO_EXPENSIVE = "Too Expensive",
  OTHER = "Other",
}

export default FEEDBACK_OPTION;
