import React, { memo } from "react";
import CloseIcon from "../Close/CloseIcon";
import * as S from "./style";

type CloseIconButtonProps = {
  height: number;
  backgroundColor?: string;
  iconColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  style?: any;
};

export type Ref = HTMLButtonElement;

const CloseIconButton = React.forwardRef<Ref, CloseIconButtonProps>(({ height, backgroundColor = "#FFFFFF33", iconColor = "#fff", onClick, style }, ref) => (
  <S.ButtonWrapper ref={ref} height={height} backgroundcolor={backgroundColor} onClick={onClick} style={style}>
    <CloseIcon fill={iconColor} />
  </S.ButtonWrapper>
));

export default memo(CloseIconButton);
