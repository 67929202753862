import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Main = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: calc(100% - 2.5rem);
  }
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 20rem;
  position: relative;
  @media (max-width: 768px) {
    height: 20rem;
  }
`;

export const Image = styled(motion.div)`
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Speed = styled(motion.img)`
  position: absolute;
  width: 5rem;
  top: 1rem;
  right: 4rem;
  @media (max-width: 768px) {
    top: 2rem;
    right: 4rem;
  }
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    top: 3rem;
    right: 4.25rem;
  }
`;

export const Square = styled(motion.img)`
  position: absolute;
  width: 9rem;
  top: 4.75rem;
  right: 2.25rem;
  @media (max-width: 768px) {
    top: 7rem;
    right: 2rem;
  }

  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    top: 8.25rem;
    right: 2.25rem;
  }
`;

export const PoliceControl = styled(motion.img)`
  position: absolute;
  width: 6rem;
  right: 0.5rem;
  bottom: 2rem;
  @media (max-width: 768px) {
    right: 0.5rem;
    bottom: 2rem;
  }
`;

export const Title = styled.div`
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
  position: relative;
  z-index: 1;
`;

export const HeaderText = styled(motion.div)``;

export const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  margin-bottom: 1.25rem;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 0.75rem;
    gap: 0.5rem;
  }
  @media (max-height: 700px) and (max-width: 768px) {
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }
`;

export const OptionIcon = styled.span`
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-height: 700px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const OptionsText = styled.span`
  min-width: 8rem;
  text-align: start;
`;

export const Option = styled(motion.div)<{ selected: number }>`
  width: calc(100% - 2rem);
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  background: ${(props) => (props.selected === 1 ? "#F6795866" : "#3D3D3D")};
  text-align: center;
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  position: relative;
  border: ${(props) => (props.selected === 1 ? "0.125rem solid #F05A22" : "0.125rem solid #3D3D3D")};
  user-select: none;
`;

export const OptionSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const SelectedIcon = styled.img`
  width: 1.5rem;
`;

export const RadioButton = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid #475399;
  border-radius: 50%;
`;

export const Bottom = styled.div<{ heightvalue: number }>`
  width: ${(props) => `${props.heightvalue / 2}px`};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;
