import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./resources";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["es", "ar", "ru", "fr", "de", "pt", "tr", "it", "th", "en"],

    detection: {
      order: ["navigator"],
      caches: [],
    },
  });

export default i18n;
