import RECEIVED_TICKET from "../enum/RECEIVED_TICKET";

const haveYouEverReceivedATrafficTicketBeforeOptions = [
  {
    id: 1,
    label: RECEIVED_TICKET.YES,
    img: "💥",
  },
  {
    id: 2,
    label: RECEIVED_TICKET.NO_TICKETS_SO_FAR,
    img: "✨",
  },
];

export default haveYouEverReceivedATrafficTicketBeforeOptions;
