import styled from "@emotion/styled";

export const EyeIconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.3125rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;

export const EyeIconSvg = styled.svg<{ width: string; height: string }>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  user-select: none;
  fill: none;
  stroke: rgba(36, 36, 36, 0.5);
`;
