import styled from "@emotion/styled";

export const Loader = styled.div<{ size: string; color: string }>`
  display: inline-block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: ${(props) => `calc(${props.size} / 8)`} solid ${(props) => props.color};
  border-top: ${(props) => `calc(${props.size} / 8)`} solid transparent;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
