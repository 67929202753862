import es from "./es";
import ar from "./ar";
import ru from "./ru";
import fr from "./fr";
import de from "./de";
import pt from "./pt";
import tr from "./tr";
import it from "./it";
import th from "./th";
import en from "./en";

const resources = { es, ar, ru, fr, de, pt, tr, it, th, en };

export default resources;
