import { TranslationKeys } from "../../model";

const fr: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Salut, à quelle fréquence voyagez-vous?",
      scanning: "Analyse",
      options: {
        Always: "Toujours",
        Mostly: "La plupart du temps",
        Occasionally: "Occasionnellement",
        Never: "Jamais",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Avez-vous déjà reçu une contravention?",
      policeControl: "Contrôle de police",
      options: {
        Yes: "Oui",
        "No tickets so far": "Non",
      },
    },
    didYouKnow: {
      title: "Connaissez-vous les amendes pour excès de vitesse?",
      pattern: "Le Saviez-Vous",
      description:
        "Les gouvernements collectent 6,2 milliards par an (ville, comté ou état). Cela représente 30% de toutes les amendes mondiales pour infractions routières émises chaque année.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Souhaitez-vous voir les radars sur la navigation?",
      options: {
        Yes: "Oui",
        No: "Non",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Laissez les amendes radar derrière vous avec notre application",
      options: {
        safeZone: "Zone de sécurité",
        niceTrip: "Bon voyage",
        safeMoney: "Économiser de l'argent",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Digne de confiance avec une moyenne de 4.6",
      secondTitle: "Plus de 2000 avis!",
      content: {
        best: "Le meilleur",
        radarDetect: "Détection Des Radars",
        app: "Mise en œuvre",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Je recommande fortement!",
          description: "La fonction de détection de radar est incroyablement précise et fiable. Elle m'a sauvé de me perdre et m'a aidé à trouver des pépites cachées incroyables.",
        },
        user2: {
          user: "Amelia R.",
          title: "Vraiment digne de cinq étoiles!",
          description: "Je l'adore! Le radar fonctionne parfaitement et m'a sauvé la mise à plusieurs reprises. Il est intuitif, facile à utiliser et très précis.",
        },
        user3: {
          user: "SarahM123",
          title: "Wow!",
          description:
            "J'ai essayé plusieurs applications de navigation, mais c'est la seule qui tient constamment sa promesse de détection fiable des radars. L'interface est conviviale.",
        },
        user4: {
          user: "Emma J.",
          title: "Super application!",
          description:
            "Elle m'a aidé à trouver les meilleurs itinéraires, à éviter le trafic et à découvrir des pépites cachées en cours de route. Les cartes hors ligne sont une bouée de sauvetage, et la navigation vocale est claire et précise.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Votre compte est prêt!",
      description: "Enregistrez maintenant votre plan personnalisé en configurant un compte",
    },
    plan: {
      title: "Repérez tous les systèmes radar avant qu'ils ne vous détectent!",
      description: "Sentez-vous en sécurité contre les amendes grâce à des cartes à jour et des alertes radar.",
      mostPopular: "LE PLUS POPULAIRE",
      payment: {
        billingDetails: "Détails de facturation",
        payNowWith: "Payer maintenant avec",
        yourPaymentisSecured: {
          title: "Votre paiement est sécurisé",
          description: "Le paiement est traité par GM Appdev Limited, avec une adresse enregistrée à Nafpliou, 15, Limassol, Chypre, 3025",
        },
        success: {
          title: "Paiement réussi",
          description: "Paiement effectué avec succès. Vous êtes redirigé vers l'application.",
        },
        failed: {
          title: "Échec du paiement",
          description: "Une erreur est survenue lors du paiement. Cliquez sur le bouton pour réessayer.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Vous n'avez pas de compte?",
      errors: {
        userNotFound: "Utilisateur non trouvé. Veuillez vous connecter",
        signInDetailsAreIncorrect: "Les informations de connexion sont incorrectes",
      },
      button: "Connexion",
    },
    register: {
      alreadyHaveAnAccount: "Vous avez déjà un compte?",
      errors: {
        emailAlreadyInUse: "E-mail déjà utilisé. Veuillez vous connecter",
        registrationInDetailsAreIncorrect: "Les informations d'inscription sont incorrectes",
      },
      button: "Créer un compte",
    },
    feedback: {
      title: "Pourquoi avez-vous décidé d'annuler?",
      options: {
        "Not Enough Time to Use the App": "Pas assez de temps pour utiliser l'application",
        "Interested in Other Apps": "Intéressé par d'autres applications",
        "Doesn't Meet My Needs": "Ne répond pas à mes besoins",
        "Lacks Motivation": "Manque de motivation",
        "Not User-Friendly": "Pas convivial",
        "Too Expensive": "Trop cher",
        Other: "Autre",
      },
    },
    paymentCancel: {
      title: "Annulation de paiement",
      errors: {
        somethingWentWrong: "Quelque chose s'est mal passé.",
        invalidCredential: "Utilisateur non trouvé",
      },
      success: {
        title: "Annulé avec succès",
      },
      failed: {
        title: "Échec de l'annulation",
        description: "Une erreur est survenue lors de l'annulation.",
      },
    },
    logOut: {
      title: "Redirection vers la page de connexion...",
    },
    notFound: {
      title: "Page non trouvée.",
      description: "Redirection vers la page d'accueil...",
    },
    common: {
      continue: "Continuer",
      getPlan: "Obtenir un plan",
      payNow: "Payer maintenant",
      tryAgain: "Réessayer",
      goToApp: "Aller à l'application",
      cancel: "Annuler",
      privacy: "Politique de confidentialité",
      terms: "Conditions d'utilisation",
      timeUnits: {
        year: "Année",
        month: "Mois",
        week: "Semaine",
      },
      billing: {
        year: "1 Année",
        month: "1 Mois",
        week: "1 Semaine",
        total: "Total",
      },
      then: "Ensuite",
      auth: {
        email: "E-mail",
        password: "Mot de passe",
        signIn: "Se connecter",
        signUp: "S'inscrire",
        errors: {
          emailIsRequired: "L'e-mail est requis",
          enterValidEmailAddress: "Veuillez entrer une adresse e-mail valide",
          passwordIsRequired: "Le mot de passe est requis",
          passwordMinChar: "Minimum 8 caractères",
        },
      },
      selectAnOption: "Sélectionnez une option",
    },
  },
};

export default fr;
