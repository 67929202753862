import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Main = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: calc(100% - 2.5rem);
  }
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 3rem;
  position: relative;

  @media (max-width: 768px) {
    height: 1rem;
  }
`;

export const Image = styled(motion.div)`
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  color: #fff;
  position: relative;
  z-index: 1;
  line-height: 3rem;
  letter-spacing: 0.01em;
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    margin-bottom: 1.5rem;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
`;

export const HeaderText = styled(motion.div)``;

export const CircleContent = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 1.5rem;
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    margin-bottom: 2.5rem;
  }
  @media only screen and (device-width: 430px) and (device-height: 932px) {
    margin-bottom: 2.5rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 5rem;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    margin-bottom: 7rem;
  }
`;

export const Stars = styled.img`
  width: 15rem;
  position: absolute;
  top: 0;
`;

export const Circle = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  background-color: #fff;
  border: 0.75rem solid #757575;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4.5rem;

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 15rem;
    height: 15rem;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    width: 15rem;
    height: 15rem;
  }
`;

export const CircleHeader = styled.div`
  color: #2d2d2d;
  font-weight: 600;
  font-size: 3rem;
  font-style: italic;
  position: absolute;
  top: 0.25rem;

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 4rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    font-size: 4rem;
  }
`;

export const CircleText = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: calc(100% - 5rem);

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    font-size: 1.25rem;
  }
`;
export const LeftLeaf = styled(motion.img)`
  width: 5rem;
  position: absolute;
  left: 0.5rem;
  bottom: 1rem;

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 7.5rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    width: 7.5rem;
  }
`;
export const RightLeaf = styled(motion.img)`
  width: 5rem;
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 7.5rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    width: 7.5rem;
  }
`;
