import { TRAVEL_OPTION } from "../enum";

const howOftenDoYouTravelOptions = [
  {
    id: 1,
    label: TRAVEL_OPTION.ALWAYS,
    img: "🤩",
  },
  {
    id: 2,
    label: TRAVEL_OPTION.MOSTLY,
    img: "😎",
  },
  {
    id: 3,
    label: TRAVEL_OPTION.OCCASIONALLY,
    img: "😉",
  },
  {
    id: 4,
    label: TRAVEL_OPTION.NEVER,
    img: "🫣",
  },
];

export default howOftenDoYouTravelOptions;
