import React, { memo } from "react";
import Svg from "../../Svg";

type Props = {
  fill?: string;
  color?: string;
  width?: string;
  height?: string;
};

const StarsIcon: React.FC<Props> = ({ fill = "#FFB100", width = "127", height = "21", color }) => (
  <Svg fill={fill} width={width} height={height} color={color} path="" viewBox="0 0 127 21">
    <path
      d="M19.3228 7.98811C19.2692 7.83019 19.1705 7.69143 19.0389 7.58896C18.9073 7.48649 18.7486 7.42479 18.5824 7.41148L13.7195 7.02508L11.6151 2.36688C11.5481 2.21685 11.4391 2.08942 11.3013 1.99998C11.1634 1.91053 11.0027 1.86288 10.8383 1.86279C10.674 1.8627 10.5132 1.91017 10.3752 1.99947C10.2373 2.08876 10.1282 2.21607 10.061 2.36603L7.95665 7.02508L3.09373 7.41148C2.93035 7.42443 2.77415 7.48417 2.64383 7.58356C2.5135 7.68295 2.41458 7.81778 2.35888 7.97193C2.30317 8.12607 2.29306 8.293 2.32975 8.45274C2.36643 8.61248 2.44836 8.75826 2.56573 8.87266L6.15939 12.3759L4.88843 17.8794C4.84984 18.046 4.86221 18.2203 4.92393 18.3798C4.98566 18.5393 5.09389 18.6765 5.23458 18.7737C5.37527 18.8709 5.54193 18.9236 5.71292 18.9249C5.88392 18.9262 6.05135 18.8761 6.19351 18.781L10.8381 15.6847L15.4826 18.781C15.6279 18.8775 15.7993 18.9272 15.9736 18.9233C16.148 18.9195 16.317 18.8624 16.4579 18.7596C16.5988 18.6569 16.7049 18.5135 16.7619 18.3486C16.8189 18.1838 16.824 18.0055 16.7766 17.8376L15.2165 12.3785L19.0856 8.89655C19.339 8.66794 19.432 8.31139 19.3228 7.98811Z"
      fill={fill}
    />
    <path
      d="M45.7627 7.98811C45.7091 7.83019 45.6104 7.69143 45.4788 7.58896C45.3473 7.48649 45.1886 7.42479 45.0223 7.41148L40.1594 7.02508L38.0551 2.36688C37.9881 2.21685 37.8791 2.08942 37.7412 1.99998C37.6034 1.91053 37.4426 1.86288 37.2783 1.86279C37.114 1.8627 36.9531 1.91017 36.8152 1.99947C36.6772 2.08876 36.5681 2.21607 36.5009 2.36603L34.3966 7.02508L29.5337 7.41148C29.3703 7.42443 29.2141 7.48417 29.0838 7.58356C28.9534 7.68295 28.8545 7.81778 28.7988 7.97193C28.7431 8.12607 28.733 8.293 28.7697 8.45274C28.8064 8.61248 28.8883 8.75826 29.0057 8.87266L32.5993 12.3759L31.3284 17.8794C31.2898 18.046 31.3021 18.2203 31.3639 18.3798C31.4256 18.5393 31.5338 18.6765 31.6745 18.7737C31.8152 18.8709 31.9819 18.9236 32.1529 18.9249C32.3239 18.9262 32.4913 18.8761 32.6335 18.781L37.278 15.6847L41.9225 18.781C42.0678 18.8775 42.2392 18.9272 42.4136 18.9233C42.5879 18.9195 42.7569 18.8624 42.8979 18.7596C43.0388 18.6569 43.1449 18.5135 43.2018 18.3486C43.2588 18.1838 43.2639 18.0055 43.2165 17.8376L41.6564 12.3785L45.5256 8.89655C45.7789 8.66794 45.8719 8.31139 45.7627 7.98811Z"
      fill={fill}
    />
    <path
      d="M72.2022 7.98811C72.1486 7.83019 72.0499 7.69143 71.9183 7.58896C71.7867 7.48649 71.628 7.42479 71.4618 7.41148L66.5989 7.02508L64.4945 2.36688C64.4275 2.21685 64.3185 2.08942 64.1807 1.99998C64.0428 1.91053 63.882 1.86288 63.7177 1.86279C63.5534 1.8627 63.3926 1.91017 63.2546 1.99947C63.1167 2.08876 63.0076 2.21607 62.9404 2.36603L60.836 7.02508L55.9731 7.41148C55.8097 7.42443 55.6535 7.48417 55.5232 7.58356C55.3929 7.68295 55.294 7.81778 55.2383 7.97193C55.1826 8.12607 55.1725 8.293 55.2091 8.45274C55.2458 8.61248 55.3278 8.75826 55.4451 8.87266L59.0388 12.3759L57.7678 17.8794C57.7292 18.046 57.7416 18.2203 57.8033 18.3798C57.8651 18.5393 57.9733 18.6765 58.114 18.7737C58.2547 18.8709 58.4213 18.9236 58.5923 18.9249C58.7633 18.9262 58.9307 18.8761 59.0729 18.781L63.7175 15.6847L68.362 18.781C68.5073 18.8775 68.6786 18.9272 68.853 18.9233C69.0274 18.9195 69.1964 18.8624 69.3373 18.7596C69.4782 18.6569 69.5843 18.5135 69.6413 18.3486C69.6983 18.1838 69.7034 18.0055 69.656 17.8376L68.0959 12.3785L71.965 8.89655C72.2184 8.66794 72.3114 8.31139 72.2022 7.98811Z"
      fill={fill}
    />
    <path
      d="M98.6421 7.98811C98.5885 7.83019 98.4898 7.69143 98.3582 7.58896C98.2267 7.48649 98.068 7.42479 97.9017 7.41148L93.0388 7.02508L90.9345 2.36688C90.8675 2.21685 90.7584 2.08942 90.6206 1.99998C90.4828 1.91053 90.322 1.86288 90.1577 1.86279C89.9934 1.8627 89.8325 1.91017 89.6946 1.99947C89.5566 2.08876 89.4475 2.21607 89.3803 2.36603L87.276 7.02508L82.4131 7.41148C82.2497 7.42443 82.0935 7.48417 81.9632 7.58356C81.8328 7.68295 81.7339 7.81778 81.6782 7.97193C81.6225 8.12607 81.6124 8.293 81.6491 8.45274C81.6858 8.61248 81.7677 8.75826 81.8851 8.87266L85.4787 12.3759L84.2078 17.8794C84.1692 18.046 84.1815 18.2203 84.2433 18.3798C84.305 18.5393 84.4132 18.6765 84.5539 18.7737C84.6946 18.8709 84.8613 18.9236 85.0323 18.9249C85.2033 18.9262 85.3707 18.8761 85.5128 18.781L90.1574 15.6847L94.8019 18.781C94.9472 18.8775 95.1186 18.9272 95.2929 18.9233C95.4673 18.9195 95.6363 18.8624 95.7772 18.7596C95.9182 18.6569 96.0243 18.5135 96.0812 18.3486C96.1382 18.1838 96.1433 18.0055 96.0959 17.8376L94.5358 12.3785L98.405 8.89655C98.6583 8.66794 98.7513 8.31139 98.6421 7.98811Z"
      fill={fill}
    />
    <path
      d="M125.082 7.98811C125.028 7.83019 124.929 7.69143 124.798 7.58896C124.666 7.48649 124.507 7.42479 124.341 7.41148L119.478 7.02508L117.374 2.36688C117.307 2.21685 117.198 2.08942 117.06 1.99998C116.922 1.91053 116.761 1.86288 116.597 1.86279C116.433 1.8627 116.272 1.91017 116.134 1.99947C115.996 2.08876 115.887 2.21607 115.82 2.36603L113.715 7.02508L108.853 7.41148C108.689 7.42443 108.533 7.48417 108.403 7.58356C108.272 7.68295 108.173 7.81778 108.118 7.97193C108.062 8.12607 108.052 8.293 108.089 8.45274C108.125 8.61248 108.207 8.75826 108.325 8.87266L111.918 12.3759L110.647 17.8794C110.609 18.046 110.621 18.2203 110.683 18.3798C110.744 18.5393 110.853 18.6765 110.993 18.7737C111.134 18.8709 111.301 18.9236 111.472 18.9249C111.643 18.9262 111.81 18.8761 111.952 18.781L116.597 15.6847L121.241 18.781C121.387 18.8775 121.558 18.9272 121.732 18.9233C121.907 18.9195 122.076 18.8624 122.217 18.7596C122.358 18.6569 122.464 18.5135 122.521 18.3486C122.578 18.1838 122.583 18.0055 122.535 17.8376L120.975 12.3785L124.844 8.89655C125.098 8.66794 125.191 8.31139 125.082 7.98811Z"
      fill={fill}
    />
  </Svg>
);

export default memo(StarsIcon);
