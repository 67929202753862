import React, { memo } from "react";
import Svg from "../../Svg";

type Props = {
  fill?: string;
  color?: string;
  width?: string;
  height?: string;
};

const WarningTriangle: React.FC<Props> = ({ fill = "#F05A22", width = "60", height = "57", color }) => (
  <Svg
    fill={fill}
    width={width}
    height={height}
    color={color}
    path="M57.8857 40.248L39.6 7.33371C37.1428 2.90513 33.7428 0.476562 30 0.476562C26.2571 0.476562 22.8571 2.90513 20.4 7.33371L2.11426 40.248C-0.200029 44.448 -0.457172 48.4766 1.39997 51.648C3.25711 54.8194 6.91426 56.5623 11.7143 56.5623H48.2857C53.0857 56.5623 56.7428 54.8194 58.6 51.648C60.4571 48.4766 60.2 44.4194 57.8857 40.248ZM27.8571 20.4766C27.8571 19.3051 28.8285 18.3337 30 18.3337C31.1714 18.3337 32.1428 19.3051 32.1428 20.4766V34.7623C32.1428 35.9337 31.1714 36.9051 30 36.9051C28.8285 36.9051 27.8571 35.9337 27.8571 34.7623V20.4766ZM32.0285 45.3623C31.8857 45.4766 31.7428 45.5908 31.6 45.7051C31.4285 45.8194 31.2571 45.9051 31.0857 45.9623C30.9143 46.048 30.7428 46.1051 30.5428 46.1337C30.3714 46.1623 30.1714 46.1908 30 46.1908C29.8285 46.1908 29.6285 46.1623 29.4285 46.1337C29.2571 46.1051 29.0857 46.048 28.9143 45.9623C28.7428 45.9051 28.5714 45.8194 28.4 45.7051C28.2571 45.5908 28.1143 45.4766 27.9714 45.3623C27.4571 44.8194 27.1428 44.0766 27.1428 43.3337C27.1428 42.5908 27.4571 41.848 27.9714 41.3051C28.1143 41.1909 28.2571 41.0766 28.4 40.9623C28.5714 40.848 28.7428 40.7623 28.9143 40.7051C29.0857 40.6194 29.2571 40.5623 29.4285 40.5337C29.8 40.448 30.2 40.448 30.5428 40.5337C30.7428 40.5623 30.9143 40.6194 31.0857 40.7051C31.2571 40.7623 31.4285 40.848 31.6 40.9623C31.7428 41.0766 31.8857 41.1909 32.0285 41.3051C32.5428 41.848 32.8571 42.5908 32.8571 43.3337C32.8571 44.0766 32.5428 44.8194 32.0285 45.3623Z"
    viewBox="0 0 60 57"
  />
);

export default memo(WarningTriangle);
