import { useState, useEffect } from "react";
import { TFunction } from "i18next";
import * as S from "./style";

type Props = {
  start: boolean;
  t: TFunction<"translation", undefined>;
};

const AnimatedTabs: React.FC<Props> = ({ start, t }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (start) {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [start]);

  const boxVariants = {
    initial: start ? { scale: 0.9, backgroundColor: "#532E20" } : { scale: 1 },
    animate: start ? { scale: 1.15, backgroundColor: "#FF5733" } : { delay: 1000 },
    reset: { scale: 1 },
  };

  return (
    <S.Container>
      <S.Box
        active={activeIndex === 0}
        variants={boxVariants}
        initial="initial"
        animate={activeIndex === 0 ? "animate" : activeIndex === 3 ? "reset" : "initial"}
        transition={{ duration: 1 }}
      >
        ⚡ {t("leaveRadarFinesBehindWithOurApp.options.safeZone")}
      </S.Box>
      <S.Box
        active={activeIndex === 1}
        variants={boxVariants}
        initial="initial"
        animate={activeIndex === 1 ? "animate" : activeIndex === 3 ? "reset" : "initial"}
        transition={{ duration: 1 }}
      >
        🚙 {t("leaveRadarFinesBehindWithOurApp.options.niceTrip")}
      </S.Box>
      <S.Box
        active={activeIndex === 2}
        variants={boxVariants}
        initial="initial"
        animate={activeIndex === 2 ? "animate" : activeIndex === 3 ? "reset" : "initial"}
        transition={{ duration: 1 }}
      >
        💰 {t("leaveRadarFinesBehindWithOurApp.options.safeMoney")}
      </S.Box>
    </S.Container>
  );
};

export default AnimatedTabs;
