import React, { memo } from "react";
import Svg from "../../Svg";

type Props = {
  fill?: string;
  color?: string;
  width?: string;
  height?: string;
};

const CheckIcon: React.FC<Props> = ({ fill = "#fff", width = "83", height = "83", color }) => (
  <Svg
    fill={fill}
    width={width}
    height={height}
    color={color}
    path="M41.4996 82.5535C64.138 82.5535 82.6603 64.0312 82.6603 41.3928C82.6603 18.7544 64.138 0.232056 41.4996 0.232056C18.8612 0.232056 0.338867 18.7544 0.338867 41.3928C0.338867 64.0312 18.8612 82.5535 41.4996 82.5535ZM61.906 26.8366C63.512 28.4454 63.5097 31.0516 61.9009 32.6576L38.5627 55.9545C36.9549 57.5595 34.3507 57.5584 32.7443 55.952L21.0958 44.3035C19.4884 42.6961 19.4884 40.0899 21.0958 38.4825C22.7032 36.875 25.3094 36.875 26.9168 38.4825L35.6573 47.223L56.085 26.8314C57.6938 25.2254 60.3 25.2277 61.906 26.8366Z"
    viewBox="0 0 83 83"
  />
);

export default memo(CheckIcon);
