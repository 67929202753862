import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context";
import { firebaseService } from "../../services/firebase.service";
import { storage } from "../../utils";
import { useGetPageInfo } from "../../hooks";
import { Cohort } from "../../utils/constants/rootConstants";
import { useTranslation } from "react-i18next";

const LogoutPage = () => {
  const navigate = useNavigate();
  const { currentCohort } = useGetPageInfo();

  const { t } = useTranslation();

  const { setAuthContext } = useAuthContext();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      firebaseService.signOut().then(() => {
        if (currentCohort === Cohort.V1) {
          navigate("/V1/login");
        }
        storage.delete("email");
        setAuthContext({ user: { email: "" }, isLoggedIn: false });
      });
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [currentCohort, navigate, setAuthContext]);

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "center", justifyContent: "center" }}>
      <div>{t("logOut.title")}</div>
    </div>
  );
};

export default LogoutPage;
