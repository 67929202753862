import { LocalConfig } from "../model";

const staticLocalConfig: LocalConfig = {
  /**
   * Static Configs
   */

  // if readable from .env, use it
  // else replace it with window.location.hostname
  HOSTNAME: process.env.REACT_APP_HOSTNAME || window.location.hostname,
  API_URL: process.env.REACT_APP_API_URL || "api.mapxplorer.net",
  CONFIG_ID: process.env.REACT_APP_CONFIG_ID || "omt",
  USER_ID: "",
  CONFIGS: {
    facebookPixelId: "",
    firebaseConfig: {
      apiKey: "",
      appId: "",
      authDomain: "",
      measurementId: "",
      messagingSenderId: "",
      projectId: "",
      storageBucket: "",
    },
    sentryDsn: "",
    stripePublishableKey: "",
  },
};

// Check api url defined
if (staticLocalConfig.API_URL === "") {
  console.error("process.env.REACT_APP_API_URL (API_URL) is undefined!");
  throw new Error("process.env.REACT_APP_API_URL (API_URL) is undefined!");
}

export { staticLocalConfig };
