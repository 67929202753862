import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import stripeService from "../../services/stripe.service";
import { firebaseService } from "../../services/firebase.service";
import { captureException } from "../../services/sentry.service";
import { useAlertContext } from "../../context";
import { useGetPageInfo, useWindowSize } from "../../hooks";
import { helpers } from "../../utils";
import { FirebaseError } from "@firebase/util";
import Feedback from "./Feedback/Feedback";
import WarningIcon from "../../components/Svg/Icons/Warning/WarningIcon";
import EyeIconButton from "../../components/Svg/Icons/EyeIconButton/EyeIconButton";
import Loading from "../../components/Loading/Loading";
import ExclamationIcon from "../../components/Svg/Icons/Exclamation/ExclamationIcon";
import CheckIcon from "../../components/Svg/Icons/Check/CheckIcon";
import * as S from "./style";

const initialValues = {
  email: "",
  password: "",
};

const CancelPage = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [canceled, setCanceled] = useState(0);

  const [showCancel, setShowCancel] = useState<boolean>(false);

  const { height } = useWindowSize();
  const { showAlert } = useAlertContext();

  const { currentCohort } = useGetPageInfo();

  const { t } = useTranslation();

  const { handleSubmit, handleChange, status, getFieldProps, touched, values, errors } = useFormik({
    initialValues,
    validate: (values) => helpers.validateEmailPassword(values, t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      firebaseService
        .signInWithEmailAndPassword({ email: values.email, password: values.password })
        .then((user) => Promise.all([user.user.getIdToken()]))
        .then(([idToken]) =>
          stripeService
            .cancelSubscription({
              configId: window.config.CONFIG_ID,
              idToken: idToken,
              email: values.email,
            })
            .then((dto) => {
              if (dto.length > 0) {
                setCanceled(1);
              } else {
                setCanceled(-1);
              }
            })
            .catch((err) => {
              console.log("Cancel Error:", err);
              showAlert(t("paymentCancel.errors.somethingWentWrong"), "error", { vertical: "top", horizontal: "right" });
            })
            .finally(() => {
              setLoading(false);
            })
        )
        .catch((err: FirebaseError) => {
          setLoading(false);
          setSubmitting(false);
          captureException(err);
          if (err.code === "auth/invalid-credential") {
            setStatus(t("paymentCancel.errors.invalidCredential"));
          } else {
            setStatus(err.code);
          }
        });
    },
  });

  if (canceled === 1) {
    return (
      <S.SuccessMessageContent>
        <S.C>
          <S.Moire>
            <CheckIcon fill="#00BB61" width="3.5rem" height="3.5rem" />
          </S.Moire>
        </S.C>
        <S.SuccessMessage cohort={currentCohort}>{t("paymentCancel.success.title")}</S.SuccessMessage>
        <S.CustomButton onClick={() => window.open(process.env.REACT_APP_MOBILE_APP_URL, "_blank")}>{t("common.goToApp")}</S.CustomButton>
      </S.SuccessMessageContent>
    );
  }

  if (canceled === -1) {
    return (
      <S.FailedMessageContent>
        <S.C>
          <S.Moire>
            <S.Triangle />
            <S.IconWrapper>
              <ExclamationIcon color="transparent" width="2.5rem" height="2.5rem" />
            </S.IconWrapper>
          </S.Moire>
        </S.C>
        <S.FailedTitle cohort={currentCohort}>{t("paymentCancel.failed.title")}</S.FailedTitle>
        <S.FailedSubTitle>
          <div>{t("paymentCancel.failed.description")}</div>
        </S.FailedSubTitle>
        <S.CustomButton onClick={() => window.open(process.env.REACT_APP_MOBILE_APP_URL, "_blank")}>{t("common.goToApp")}</S.CustomButton>
      </S.FailedMessageContent>
    );
  }

  return (
    <>
      {showCancel ? (
        <S.Main heightvalue={height}>
          <S.Form onSubmit={handleSubmit}>
            <S.Header>
              <S.HeaderText>{t("paymentCancel.title")}</S.HeaderText>
            </S.Header>
            <S.Content>
              <S.InputWrapper>
                <S.InputLabel>{t("common.auth.email")}</S.InputLabel>
                <S.Input
                  {...getFieldProps("email")}
                  type="email"
                  error={errors.email ? true : false}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="email"
                />
                {touched.email && errors.email && (
                  <S.WarningContent>
                    <WarningIcon fill="#20C4E5" />
                    <div>{errors.email}</div>
                  </S.WarningContent>
                )}
              </S.InputWrapper>
              <S.InputWrapper>
                <S.InputLabel>{t("common.auth.password")}</S.InputLabel>
                <S.InputContent>
                  <S.Input
                    type={showPassword ? "text" : "password"}
                    error={touched.password && errors.password ? true : false}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                  <EyeIconButton show={showPassword} fill="#fff" clicked={() => setShowPassword(!showPassword)} />
                </S.InputContent>
                {touched.password && errors.password && (
                  <S.WarningContent>
                    <WarningIcon fill="#20C4E5" />
                    <div>{errors.password}</div>
                  </S.WarningContent>
                )}
              </S.InputWrapper>
            </S.Content>
            <S.Bottom heightvalue={height}>
              <S.StyledButton type="submit" disabled={!values.email || !values.password}>
                {loading ? <Loading size="1.25rem" /> : t("common.cancel")}
              </S.StyledButton>
            </S.Bottom>
            {!!status && (
              <S.ErrorContent>
                <WarningIcon fill="#20C4E5" />
                <div>{status}</div>
              </S.ErrorContent>
            )}
          </S.Form>
        </S.Main>
      ) : (
        <Feedback clicked={() => setShowCancel(true)} />
      )}
    </>
  );
};

export default CancelPage;
