import styled from "@emotion/styled";
import { motion } from "framer-motion";
import Lottie from "lottie-react";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "25rem")};
  /* max-width: 25rem; */
  height: 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 27rem;
  position: relative;
  @media (max-width: 768px) {
    height: 23rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    height: 36rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    height: 42.5rem;
  }
`;

export const Image = styled(motion.div)`
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BottomFrames = styled.div`
  position: absolute;
  bottom: -1.5rem;
  width: 15rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0rem;
  @media (max-width: 768px) {
    bottom: -2rem;
  }
`;

export const StyledLightIcon = styled(motion.div)`
  z-index: 2;
  bottom: 1rem;
  width: 2.5rem;
  display: block;
`;

export const DidYouKnow = styled(motion.div)`
  display: block;
  z-index: 1;
  bottom: 2rem;
  width: 12rem;
`;

export const Pattern = styled(motion.img)`
  display: block;
  z-index: 1;
  bottom: 2rem;
  width: 12rem;
  height: 3.25rem;
`;

export const PatternText = styled(motion.div)`
  display: block;
  z-index: 1;
  position: absolute;
  color: #000;
  top: 2rem;
  left: 4.5rem;
  font-size: 0.875rem;
  font-weight: 700;

  // Ipad Pro 12.9 6th gen
  @media (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    top: 2rem;
  }
`;

export const ProgressContent = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 4rem;
  width: 100%;
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
`;

export const HeaderText = styled(motion.div)`
  display: block;
  position: relative;
  color: #fff;
`;

export const Description = styled(motion.div)`
  display: block;
  position: relative;
  font-size: 1rem;
  padding: 0 1rem;
  /* text-align: justify; */
  text-align: center;
  font-weight: 400;
  line-height: 1.5rem;
  color: #fff;
`;

export const ColoredText = styled.span`
  color: #f05a22;
  user-select: none;
  font-weight: 700;
  font-size: 1.125rem;
`;

export const StyledLottie = styled(Lottie)<{ height: number }>`
  position: absolute;
  user-select: none;
  width: ${(props) => (props.height ? `${props.height / 2}px` : "25rem")};
  top: -3rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    top: 0;
    width: 100%;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 744px) and (max-device-width: 1024px) and (max-device-height: 1133px) {
    width: 100%;
  }
`;
