import { TFunction } from "i18next";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import ExclamationIcon from "../../../../../components/Svg/Icons/Exclamation/ExclamationIcon";
import * as S from "./style";

type PaymentFailedProps = {
  show: boolean;
  onTryAgain: () => void;
  t: TFunction<"translation", undefined>;
};

const PaymentFailed: React.FC<PaymentFailedProps> = ({ show, onTryAgain, t }) => {
  return (
    <CustomModal show={show}>
      <S.Container>
        <S.C>
          <S.Moire>
            <S.Triangle />
            <S.IconWrapper>
              <ExclamationIcon color="transparent" width="2.5rem" height="2.5rem" />
            </S.IconWrapper>
          </S.Moire>
        </S.C>
        <S.Title>{t("plan.payment.failed.title")}</S.Title>
        <S.SubTitle>
          {/* <div>An error occurred during payment. Click</div>
          <div>the button to try again.</div> */}
          {t("plan.payment.failed.description")}
        </S.SubTitle>
        <S.CustomButton onClick={onTryAgain}>{t("common.tryAgain")}</S.CustomButton>
      </S.Container>
    </CustomModal>
  );
};

export default PaymentFailed;
