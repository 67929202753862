import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Main = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow: hidden;
  touch-action: none;
  position: relative;
  -webkit-overflow-scrolling: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: calc(100% - 2.5rem);
  }
`;

export const PageContent = styled.div<{ width: number | string }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "25rem")};
  max-width: 25rem;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`;

export const Title = styled(motion.div)`
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;

export const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  margin-bottom: 1.25rem;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.25rem;
    gap: 0.75rem;
  }
  @media (max-height: 700px) and (max-width: 768px) {
    margin-bottom: 0.75rem;
    gap: 0.75rem;
  }
`;

export const Option = styled(motion.div)<{ selected: number }>`
  width: calc(100% - 2rem);
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 1rem;
  background: ${(props) => (props.selected === 1 ? "#F6795866" : "#3D3D3D")};
  text-align: center;
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  position: relative;
  border: ${(props) => (props.selected === 1 ? "0.125rem solid #F05A22" : "0.125rem solid #3D3D3D")};
  user-select: none;

  @media (max-width: 768px) {
    height: 2.5rem;
  }
`;

export const OptionsText = styled.span`
  min-width: 16rem;
  text-align: start;
`;

export const SelectedIcon = styled.img`
  width: 1.5rem;
`;

export const OptionSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const RadioButton = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid #475399;
  border-radius: 50%;
`;

export const Bottom = styled.div<{ heightvalue: number }>`
  width: ${({ heightvalue }) => `${heightvalue / 2}px`};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;
