import React, { memo } from "react";
import * as S from "./style";

type CustomButtonProps = {
  text: string;
  width?: number | string;
  disabled?: boolean;
  customDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export type Ref = HTMLButtonElement;

const CustomButton = React.forwardRef<Ref, CustomButtonProps>(({ text, width = "25rem", disabled, customDisabled, onClick }, ref) => (
  <S.CustomButtonContainer>
    <S.CustomButton ref={ref} width={width} disabled={disabled} customdisabled={customDisabled ? 1 : 0} onClick={onClick}>
      {text}
    </S.CustomButton>
  </S.CustomButtonContainer>
));

export default memo(CustomButton);
