import { TranslationKeys } from "../../model";

const tr: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Merhaba, Ne sıklıkla seyahat edersiniz?",
      scanning: "Tarama",
      options: {
        Always: "Her zaman",
        Mostly: "Çoğunlukla",
        Occasionally: "Ara Sıra",
        Never: "Hiç",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Daha önce hiç trafik cezası aldınız mı?",
      policeControl: "Polis Konrolü",
      options: {
        Yes: "Evet",
        "No tickets so far": "Şu ana kadar hiç ceza almadım",
      },
    },
    didYouKnow: {
      title: "Radar hız cezaları hakkında bilginiz var mı?",
      pattern: "Biliyor musun",
      description: "Hükümetler yılda 6.2 milyar dolar topluyor (şehir, ilçe veya devlet). Bu, yılda verilen tüm küresel trafik cezalarının %30'unu oluşturuyor.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Navigasyonda radarları görmek ister misin?",
      options: {
        Yes: "Evet",
        No: "Hayır",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Uygulamamızla Radar Cezalarını Geride Bırakın",
      options: {
        safeZone: "Güvenli Bölge",
        niceTrip: "Keyifli Seyahat",
        safeMoney: "Güvenli Para",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "4.6 Ortalama ile Güvenilir",
      secondTitle: "2000'den fazla Yorum!",
      content: {
        best: "En iyi",
        radarDetect: "Radar Tespit",
        app: "Uygulaması",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Kesinlikle tavsiye ederim!",
          description: "Radar algılama özelliği inanılmaz derecede doğru ve güvenilir. Kaybolmamı önledi ve harika gizli mücevherler bulmamı sağladı.",
        },
        user2: {
          user: "Amelia R.",
          title: "Kesinlikle beş yıldıza değer!",
          description: "Kesinlikle bayıldım! Radar kusursuz çalışıyor ve birçok durumda hayat kurtarıcı oldu. Kullanımı sezgisel, kullanımı kolay ve çok doğru.",
        },
        user3: {
          user: "SarahM123",
          title: "Vay!",
          description: "Birkaç navigasyon uygulamasını denedim, ancak bu güvenilir radar algılama sözünü tutan tek uygulama. Arayüzü kullanıcı dostu.",
        },
        user4: {
          user: "Emma J.",
          title: "Harika uygulama!",
          description: "Bana en iyi rotaları bulmamda, trafikten kaçınmamda ve yol boyunca gizli hazineleri keşfetmemde yardımcı oldu. Çevrimdışı haritalar hayat kurtarıcı.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Hesabınız hazır!",
      description: "Şimdi bir hesap oluşturarak kişiselleştirilmiş planınızı kaydedin.",
    },
    plan: {
      title: "Tüm radar sistemlerini onlar sizi tespit etmeden önce tespit edin!",
      description: "Güncel haritalar ve radar uyarıları sayesinde cezalara karşı kendinizi güvende hissedin.",
      mostPopular: "EN POPÜLER",
      payment: {
        billingDetails: "Fatura Detayları",
        payNowWith: "Ödeme Yöntemi",
        yourPaymentisSecured: {
          title: "Ödemeniz güvence altındadır.",
          description: "GM Appdev Limited tarafından gerçekleştirilen ödeme, kayıtlı adresi Nafpliou, 15, Limasol, Kıbrıs, 3025 olan şirket tarafından yapılmıştır.",
        },
        success: {
          title: "Ödeme Başarılı",
          description: "Ödeme başarıyla gerçekleştirildi. Uygulamaya yönlendiriliyorsunuz.",
        },
        failed: {
          title: "Ödeme Başarısız",
          description: "Ödeme sırasında bir hata oluştu. Tekrar denemek için butona tıklayın.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Hesabınız yok mu?",
      errors: {
        userNotFound: "Kullanıcı bulunamadı. Lütfen kayıt olun.",
        signInDetailsAreIncorrect: "Giriş bilgileri yanlış.",
      },
      button: "Giriş",
    },
    register: {
      alreadyHaveAnAccount: "Zaten bir hesabınız var mı?",
      errors: {
        emailAlreadyInUse: "E-posta zaten kullanılıyor. Lütfen giriş yapın.",
        registrationInDetailsAreIncorrect: "Kayıt bilgileri yanlış",
      },
      button: "Hesap Oluştur",
    },
    feedback: {
      title: "Neden iptal etmeye karar verdiniz?",
      options: {
        "Not Enough Time to Use the App": "Uygulamayı kullanmak için yeterli zamanım yok",
        "Interested in Other Apps": "Diğer uygulamalara ilgi duyuyorum",
        "Doesn't Meet My Needs": "İhtiyaçlarımı karşılamıyor",
        "Lacks Motivation": "Motivasyon eksikliği",
        "Not User-Friendly": "Kullanıcı dostu değil",
        "Too Expensive": "Çok pahalı",
        Other: "Diğer",
      },
    },
    paymentCancel: {
      title: "Ödeme İptali",
      errors: {
        somethingWentWrong: "Bir şeyler ters gitti.",
        invalidCredential: "Kullanıcı bulunamadı",
      },
      success: {
        title: "Başarıyla İptal Edildi",
      },
      failed: {
        title: "İptal Başarısız",
        description: "İptal sırasında bir hata oluştu.",
      },
    },
    logOut: {
      title: "Giriş sayfasına yönlendiriliyorsunuz...",
    },
    notFound: {
      title: "Sayfa bulunamdı.",
      description: "Başlangıç sayfasına yönlendiriliyorsunuz...",
    },
    common: {
      continue: "Devam et",
      getPlan: "Planı Getir",
      payNow: "Şimdi Öde",
      tryAgain: "Tekrar Dene",
      goToApp: "Uygulamaya git",
      cancel: "İptal",
      privacy: "Gizlilik",
      terms: "Koşullar",
      timeUnits: {
        year: "Yıl",
        month: "Ay",
        week: "hafta",
      },
      billing: {
        year: "Yıllık",
        month: "Aylık",
        week: "Haftalık",
        total: "Toplam",
      },
      then: "Sonra",
      auth: {
        signIn: "Giriş Yap",
        signUp: "Kayıt Ol",
        email: "E-posta",
        password: "Şifre",
        errors: {
          emailIsRequired: "E-posta zorunlu",
          enterValidEmailAddress: "Lütfen geçerli bir e-posta adresi girin",
          passwordIsRequired: "Şifre zorunlu",
          passwordMinChar: "En az 8 karakter",
        },
      },
      selectAnOption: "Bir Seçenek Belirleyin",
    },
  },
};

export default tr;
