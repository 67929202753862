import { useEffect, useRef, useMemo, Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { firebaseService } from "../../services/firebase.service";
import { useGetPageInfo } from "../../hooks";
import { helpers } from "../../utils";
import PrivateRoute from "./PrivateRoute";
import PageLayout from "../PageLayout/PageLayout";
import AppLoading from "../../components/AppLoading/AppLoading";
import Login from "../../pages/Cohort1/Login/Login";
import LogoutPage from "../../pages/Logout/Logout";
import NotFoundPage from "../../pages/NotFound/NotFound";
import CancelPage from "../../pages/Cancel/Cancel";
import PolicyPage from "../../pages/Policy/Policy";
import TosPage from "../../pages/Tos/Tos";
import SubscriptionTermsPage from "../../pages/SubscriptionTerms/SubscriptionTerms";
import "./style.scss";

const AppRouter: React.FC = () => {
  const { currentVariantPageList, currentCohort, currentPageId } = useGetPageInfo();

  const nodeRef = useRef(null);

  const firstPagePath = useMemo(
    () =>
      helpers.getPathFromPageId({
        cohort: currentCohort,
        pageId: currentVariantPageList[0].id,
      }),
    [currentCohort, currentVariantPageList]
  );

  useEffect(() => {
    firebaseService?.logFirebaseScreenView("/" + currentCohort + "/" + currentPageId);
  }, [currentCohort, currentPageId]);

  const routes = useRoutes([
    {
      index: true,
      element: <Navigate to={firstPagePath} />,
    },
    {
      element: <Outlet />,
      children: currentVariantPageList.map(({ id: pageId }, index, arr) => {
        const CurrentPage = helpers.getIdToPageMap(pageId);
        const nextPagePath = helpers.getPathFromPageId({
          cohort: currentCohort,
          pageId: arr[index + 1]?.id,
        });
        const fixedNextPagePath = arr[index + 1]?.id === undefined ? firstPagePath : nextPagePath;
        window.scrollTo(0, 0);
        return {
          path: `${currentCohort}/${pageId}`,
          element: (
            <PrivateRoute>
              <SwitchTransition>
                <CSSTransition key={pageId} exit={true} appear={true} timeout={200} nodeRef={nodeRef} classNames="fade">
                  <CurrentPage pageId={pageId} nextPagePath={fixedNextPagePath} />
                </CSSTransition>
              </SwitchTransition>
            </PrivateRoute>
          ),
        };
      }),
    },
    {
      path: "/V1/login",
      element: (
        <PrivateRoute>
          <Login />
        </PrivateRoute>
      ),
    },
    {
      path: "/cancel",
      element: (
        <PrivateRoute>
          <CancelPage />
        </PrivateRoute>
      ),
    },
    {
      path: `${currentCohort}/logout`,
      element: (
        <PrivateRoute>
          <LogoutPage />
        </PrivateRoute>
      ),
    },
    { path: "/policy", element: <PolicyPage /> },
    { path: "/tos", element: <TosPage /> },
    { path: "/subs-terms", element: <SubscriptionTermsPage /> },
    { path: "*", element: <NotFoundPage /> },
  ]);

  return (
    <PageLayout>
      <Suspense fallback={<AppLoading />}>{routes}</Suspense>
    </PageLayout>
  );
};

export default AppRouter;
