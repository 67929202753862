import React from "react";
import styled from "@emotion/styled";

type ModalProps = {
  show: boolean;
  children: React.ReactNode;
};

const CustomModal: React.FC<ModalProps> = ({ show, children }) => {
  if (!show) return null;

  return (
    <Backdrop>
      <ModalContainer>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Backdrop>
  );
};

export default CustomModal;

// Styled components
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ModalContainer = styled.div`
  max-width: 40rem;
  height: 100%;
  margin: 0 auto;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 0.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
`;
