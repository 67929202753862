import * as S from "./style";

const SubscriptionTermsPage = () => {
  return (
    <S.Main>
      <h1>SUBSCRIPTION TERMS</h1>
      <S.P>
        <br />
        <br />
        Subscription
      </S.P>
      <S.P>
        When you subscribe to our app, you agree to the following terms: You will be billed on a recurring basis, at the rate agreed upon when you sign up for the subscription.
        This subscription will remain in effect until you or we deactivate it. While you can change your subscription plan at any time, please note that changes will take effect at
        the beginning of your next billing cycle. If you cancel your subscription, you won't be charged any further fees post-cancellation, but you won't be refunded for the unused
        portion of your current subscription period.
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>Payment Method</S.P>
      <S.P>
        You can pay for your subscription using various methods including credit card, PayPal, or Google Pay. Payment will be charged through our third-party payment processor. You
        are responsible for maintaining the accuracy of the payment method information in your account.
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>Cancellation</S.P>
      <S.P>To cancel your subscription, please follow these steps:</S.P>
      <S.P>- Log in to your account.</S.P>
      <S.P>
        - Go to Settings (or{" "}
        <S.Link target="_blank" href={`${window.location.origin}/cancel`}>
          {`${window.location.origin}/cancel`}
        </S.Link>
        )
      </S.P>
      <S.P>- After redirection to the cancellation web page, confirm cancellation by pressing the "Cancel" Button.</S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>Changes</S.P>
      <S.P>
        We reserve the right to modify our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our
        sole and absolute discretion. We may change subscription fees at any time.
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>Refunds</S.P>
      <S.P>
        We typically do not offer refunds for unused portions of your subscription period. However, any exceptional circumstances for refunds will be considered on a case-by-case
        basis.
      </S.P>
      <S.P>We would like to inform you that no refund will be applied for the following reasons.</S.P>
      <S.P>- Reasons such as you did not like the product and did not meet your expectations</S.P>
      <S.P>- Accidental payments</S.P>
      <S.P>- For products that start with the trial period, in cases where you think you do not expect to be paid after the trial period</S.P>
      <S.P>If you did not get visible results after following the Content recommendations and met all of the following conditions:</S.P>
      <S.P>- You contact us within 30 (thirty) calendar days as of the Purchase date</S.P>
      <S.P>- You have followed our personalized workout plan at least during 14 (fourteen) consecutive days</S.P>
      <S.P>
        - You are able to demonstrate that you have actually followed the plan by providing us with a screen video or screenshots of workout plan history, which you have personally
        passed.
      </S.P>
      <S.P>
        To be eligible for a refund on the grounds of not getting visible results, the minimum number of completed workouts in your plan during 14 (fourteen) consecutive days (two
        weeks) should be equal to at least 1 (one) workout session each day..
      </S.P>
      <S.P>All refunds will be calculated according to the actual Purchase price paid at the time of Purchase.</S.P>
      <S.P>
        If you want to cancel your Purchase and are entitled to any refund, we reserve the right to charge a fee to cover the cost of any administrative or other expenses we may
        have suffered due to your order, to the extent permitted by law.
      </S.P>
      <S.P>
        To request a refund, please contact Website customer service at{" "}
        <S.Link target="_blank" href="mailto:beintouch@oceanfloatmobile.com">
          beintouch@oceanfloatmobile.com
        </S.Link>
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>Contact &amp; Support</S.P>
      <S.P>
        For any queries regarding your subscription or if you encounter any issues, please reach out to us at&nbsp;{" "}
        <S.Link target="_blank" href="mailto:beintouch@oceanfloatmobile.com">
          beintouch@oceanfloatmobile.com
        </S.Link>
        &nbsp;Our dedicated team is always available to assist you and aims to resolve any issues as quickly as possible.
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>
        <br />
      </S.P>
      <S.P>
        <br />
      </S.P>
    </S.Main>
  );
};

export default SubscriptionTermsPage;
