import styled from "@emotion/styled";
import bgMap from "../../assets/img/auth/bg_map.webp";

export const Main = styled.div<{ heightvalue: number }>`
  position: relative;
  width: ${(props) => `${props.heightvalue / 2}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow: hidden;
  touch-action: none;
  user-select: none;

  @media (max-width: 768px) {
    width: 90%;
    margin: 1.5rem auto 0;
  }
`;

export const Header = styled.div`
  padding: 1rem 0;
  color: #fff;
  margin: 1rem 0;
  width: 100%;
  border-radius: 0.75rem;
  background: url(${bgMap}), linear-gradient(94.77deg, #10567c -9.59%, #23dbf5 118.74%);
`;

export const HeaderText = styled.div`
  font-size: 1.75rem;
  padding: 0 1rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #282828;
  padding: 1rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
`;

export const InputWrapper = styled.div`
  width: 90%;
  margin: 0.5rem 0;
`;

export const InputLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
  text-transform: unset;
  color: #fff;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input<{ error: boolean }>`
  color: #fff !important;
  width: -webkit-fill-available;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  border-color: ${(props) => (props.error ? "#F02222" : "#9B9B9B")};
  border-style: solid !important;
  outline: unset !important;
  outline-offset: 0 !important;
  padding: 1rem !important;
  background-color: ${(props) => (props.error ? "#F022221A" : "#282828")} !important;
  border-radius: 0.25rem;

  &:focus-visible {
    outline: unset !important;
    outline-offset: 0;
    background-color: ${(props) => (props.error ? "#F022221A" : "#f05a221a")} !important;
    border-color: ${(props) => (props.error ? "#F02222" : "#f05a22")};
    border-style: solid !important;
  }
`;

export const WarningContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  gap: 0.5rem;
  color: #9b9b9b;
  margin-top: 0.5rem;
`;

export const Bottom = styled.div<{ heightvalue: number }>`
  width: ${(props) => `${props.heightvalue / 2}px`};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
  border-radius: 1.5rem;
  height: 3rem;
  background: #f05a22;
  color: #fff;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  text-transform: unset;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: unset;
  border: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.875rem;
  gap: 0.5rem;
  color: #f02222;
`;

export const SuccessMessageContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24rem;
  background: linear-gradient(0deg, rgba(35, 45, 73, 1) 70%, rgba(0, 187, 97, 0.4) 95%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: rgba(35, 45, 73, 1);

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #ffffff1a;
    border-radius: 1rem;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const C = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 7rem;
  position: relative;
`;

export const Moire = styled.div`
  background: rgba(0, 187, 97, 0.1);
  height: 6rem;
  width: 6rem;
  position: absolute;
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 2rem;
`;

export const SuccessMessage = styled.div<{ cohort: string }>`
  color: #fff;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const FailedMessageContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24rem;
  background: linear-gradient(0deg, rgba(35, 45, 73, 1) 70%, rgba(255, 147, 0, 0.5) 95%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: rgba(35, 45, 73, 1);

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #ffffff1a;
    border-radius: 1rem;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FailedTitle = styled.div<{ cohort: string }>`
  color: #fff;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const FailedSubTitle = styled.div`
  color: #8e98b4;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: lighter;
`;

export const CustomButton = styled.button`
  width: 90%;
  border-radius: 1.5rem;
  background: #ff9300;
  text-transform: unset;
  margin: 1.5rem 0;
  height: 3.5rem;
  color: #fff;
  text-transform: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  z-index: 999;
`;

export const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
  pointer-events: none;
  border-radius: 20%;
  width: 5rem;
  height: 5rem;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #ff9300;
    pointer-events: auto;
    width: 5rem;
    height: 5rem;
  }
  &::before {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
    border-radius: 20% 20% 20% 55%;
  }
  &::after {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
    border-radius: 20% 20% 55% 20%;
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 1;
  width: 2rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContent = styled.div`
  position: relative;
`;
