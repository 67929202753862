import { memo } from "react";
import * as S from "./style";

type Props = {
  size?: string;
  color?: string;
};

const Loading: React.FC<Props> = ({ size = "3rem", color = "#fff" }) => <S.Loader size={size} color={color} />;

export default memo(Loading);
