import React, { memo } from "react";
import Svg from "../../Svg";

type Props = {
  fill?: string;
  color?: string;
  width?: string;
  height?: string;
};

const CloseIcon: React.FC<Props> = ({ fill = "#fff", width = "0.875rem", height = "0.875rem", color }) => (
  <Svg fill="none" width={width} height={height} color={color} path="" viewBox="0 0 14 14">
    <path d="M12.992 1.00005L0.906787 13.1663M0.906738 1L12.992 13.1663" stroke={fill} stroke-width="1.60439" stroke-linecap="round" />
  </Svg>
);

export default memo(CloseIcon);
