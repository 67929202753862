import styled from "@emotion/styled";

export const ButtonWrapper = styled.button<{ height: number; backgroundcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.backgroundcolor};
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
  min-width: unset;
  position: relative;
  margin-bottom: 0.5rem;
  left: ${(props) => props.height / 4}px;
  top: 0;
  padding: 1rem;
  border: none;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.backgroundcolor};
  }

  @media (max-width: 768px) {
    left: unset;
    right: calc(-50% + 1.25rem);
  }
`;
