import React, { createRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { TFunction } from "i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { stripePromise } from "../../../../../services/stripe.service";
import { useWindowSize } from "../../../../../hooks";
import { Stripe } from "../../../../../model";
import { StripeElementsOptions } from "@stripe/stripe-js";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import CloseIconButton from "../../../../../components/Svg/Icons/CloseIconButton/CloseIconButton";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import * as S from "./style";

type CheckoutProps = {
  plan?: Stripe.StripePrice;
  show: boolean;
  backdropClick: () => void;
  clientSecret: string;
  onPaymentFailed: () => void;
  t: TFunction<"translation", undefined>;
};

const Checkout: React.FC<CheckoutProps> = ({ plan, show, backdropClick, clientSecret, onPaymentFailed, t }) => {
  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "night",
      variables: {
        colorBackground: "#393939",
        colorText: "#fff",
        colorDanger: "#df1b41",
        colorPrimary: "#00BB61",
        spacingUnit: "0.25rem",
        borderRadius: "0.75rem",
        fontWeightNormal: "600",
        focusBoxShadow: "#E5FA401A",
        focusOutline: "#E5FA40",
        colorTextPlaceholder: "#8E98B4",
      },
    },
  };

  return (
    <>
      <CustomModal show={show}>
        <S.Container>
          <S.CloseButtonWrapper>
            <CloseIconButton backgroundColor="#FFFFFF33" iconColor="#fff" height={height} onClick={backdropClick} />
          </S.CloseButtonWrapper>
          <S.Content height={height}>
            <S.Title>{t("plan.payment.billingDetails")}</S.Title>
            {plan && (
              <>
                <S.Price>
                  <div style={{ display: "flex", justifyContent: "space-between", color: "#FFFFFF99", fontSize: "1.25rem" }}>
                    <div>{t(`common.billing.${plan.recurring.interval}`)}</div>
                    <div>
                      {getSymbolFromCurrency(plan.currency)}
                      {Math.round((plan?.unit_amount / 100) * 100) / 100}
                    </div>
                  </div>
                  <S.Hr />
                  <div style={{ display: "flex", justifyContent: "space-between", color: "#fff", fontSize: "1.25rem" }}>
                    <div style={{ fontSize: "1.125rem" }}>{t("common.billing.total")}</div>
                    <S.Total>
                      <div>
                        {getSymbolFromCurrency(plan.currency)}
                        {Math.round((plan?.unit_amount / 100) * 100) / 100}
                      </div>
                      <div style={{ color: "#FFFFFF99", fontSize: "0.875rem" }}>
                        {t("common.then")} {getSymbolFromCurrency(plan.currency)}
                        {Math.round((plan?.unit_amount / 100) * 100) / 100} / {t(`common.timeUnits.${plan.recurring.interval}`)}
                      </div>
                    </S.Total>
                  </div>
                </S.Price>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm ref={myRef} onPaymentFailed={onPaymentFailed} t={t} />
                  </Elements>
                )}
              </>
            )}
            <S.Bottom>
              <S.BottomTitle>
                {/* <SecuredIcon fill="#fff" /> */}
                <div>{t("plan.payment.yourPaymentisSecured.title")}</div>
              </S.BottomTitle>
              {/* <S.BankCards src={cards} alt="" /> */}
              <S.BottomDesc>{t("plan.payment.yourPaymentisSecured.description")}</S.BottomDesc>
            </S.Bottom>
          </S.Content>
        </S.Container>
      </CustomModal>
    </>
  );
};

export default Checkout;
