import { TranslationKeys } from "../../model";

const en: TranslationKeys = {
  translation: {
    howOftenDoYouTravel: {
      title: "Hi, How often do you travel?",
      scanning: "Scanning",
      options: {
        Always: "Always",
        Mostly: "Mostly",
        Occasionally: "Occasionally",
        Never: "Never",
      },
    },
    haveYouEverReceivedATrafficTicketBefore: {
      title: "Have you ever received a traffic ticket before?",
      policeControl: "Police Control",
      options: {
        Yes: "Yes",
        "No tickets so far": "No tickets so far",
      },
    },
    didYouKnow: {
      title: "Do you know about radar speed tickets?",
      pattern: "Did You Know",
      description: "Governments collect 6.2 billion yearly (city, county, or state). This is 30% of all global traffic fines issued annually.",
    },
    wouldYouLikeToSeeTheRadarsOnTheNavigation: {
      title: "Would you like to see the radars on the navigation?",
      options: {
        Yes: "Yes",
        No: "No",
      },
    },
    leaveRadarFinesBehindWithOurApp: {
      title: "Leave Radar Fines Behind with Our App",
      options: {
        safeZone: "Safe Zone",
        niceTrip: "Nice Trip",
        safeMoney: "Safe Money",
      },
    },
    bestRadarDetectApp: {
      firstTitle: "Trusted with a 4.6 Average",
      secondTitle: "Over 2000+ Reviews!",
      content: {
        best: "Best",
        radarDetect: "Radar Detect",
        app: "App",
      },
      reviews: {
        user1: {
          user: "Mike B.",
          title: "Highly recommend!",
          description: "The radar detection feature is incredibly accurate and reliable. It's saved me from getting lost and helped me find some amazing hidden gems.",
        },
        user2: {
          user: "Amelia R.",
          title: "Definitely worth five stars!",
          description: "Absolutely love it! The radar works flawlessly and has been a lifesaver on multiple occasions. It’s intuitive, easy to use, and very accurate.",
        },
        user3: {
          user: "SarahM123",
          title: "Wow!",
          description:
            "I've tried several navigation apps, but this is the only one that consistently delivers on its promise of reliable radar detection. The interface is user-friendly.",
        },
        user4: {
          user: "Emma J.",
          title: "Great app!",
          description:
            "It's helped me find the best routes, avoid traffic, and discover hidden gems along the way. The offline maps are a lifesaver, and the voice navigation is clear and concise.",
        },
      },
    },
    yourAccountIsReady: {
      title: "Your account is ready!",
      description: "Now save your personalized plan by setting up an account",
    },
    plan: {
      title: "Spot All Radar Systems Before They Detect You!",
      description: "Feel safe against the fines thanks to up-to-date maps and radar alerts.",
      mostPopular: "MOST POPULAR",
      payment: {
        billingDetails: "Billing Details",
        payNowWith: "Pay now with",
        yourPaymentisSecured: {
          title: "Your payment is secured",
          description: "The payment processed by GM Appdev Limited, with registered address at Nafpliou, 15, Limassol, Cyprus, 3025",
        },
        success: {
          title: "Payment Successful",
          description: "Payment made successfully.You are directed to the application.",
        },
        failed: {
          title: "Payment Failed",
          description: "An error occurred during payment. Click the button to try again.",
        },
      },
    },
    login: {
      dontHaveAnAccount: "Don't have an account?",
      errors: {
        userNotFound: "User not found. Please Sign In",
        signInDetailsAreIncorrect: "The signin details are incorrect",
      },
      button: "Login",
    },
    register: {
      alreadyHaveAnAccount: "Already have an account?",
      errors: {
        emailAlreadyInUse: "Email already in use. Please Sign in",
        registrationInDetailsAreIncorrect: "The registration details are incorrect",
      },
      button: "Create Account",
    },
    feedback: {
      title: "Why have you decided to cancel?",
      options: {
        "Not Enough Time to Use the App": "Not Enough Time to Use the App",
        "Interested in Other Apps": "Interested in Other Apps",
        "Doesn't Meet My Needs": "Doesn't Meet My Needs",
        "Lacks Motivation": "Lacks Motivation",
        "Not User-Friendly": "Not User-Friendly",
        "Too Expensive": "Too Expensive",
        Other: "Other",
      },
    },
    paymentCancel: {
      title: "Payment Cancel",
      errors: {
        somethingWentWrong: "Something went wrong.",
        invalidCredential: "User not found",
      },
      success: {
        title: "Successfully Cancelled",
      },
      failed: {
        title: "Cancel Failed",
        description: "An error occurred during cancel.",
      },
    },
    logOut: {
      title: "Redirecting to Signin Page...",
    },
    notFound: {
      title: "Page Not Found.",
      description: "Redirecting to Welcome Page...",
    },
    common: {
      continue: "Continue",
      getPlan: "Get Plan",
      payNow: "Pay Now",
      tryAgain: "Try Again",
      goToApp: "Go to App",
      cancel: "Cancel",
      privacy: "Privacy Policy",
      terms: "Terms of Use",
      timeUnits: {
        year: "Year",
        month: "Month",
        week: "week",
      },
      billing: {
        year: "1 Year",
        month: "1 Month",
        week: "1 week",
        total: "Total",
      },
      then: "Then",
      auth: {
        email: "E-mail",
        password: "Password",
        signIn: "Sign in",
        signUp: "Sign up",
        errors: {
          emailIsRequired: "Email is required",
          enterValidEmailAddress: "Please enter a valid email address",
          passwordIsRequired: "Password is required",
          passwordMinChar: "Minimum 8 characters",
        },
      },
      selectAnOption: "Select an Option",
    },
  },
};

export default en;
